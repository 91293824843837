enum AuthStateKind {
  Unauthenticated = 'Unauthenticated',
  Authenticating = 'Authenticating',
  Authenticated = 'Authenticated',
}

export type AuthState = {
  isAuthenticated: boolean
  kind: AuthStateKind
}

export const Authenticating: AuthState = {
  isAuthenticated: false,
  kind: AuthStateKind.Authenticating,
}

export const Unauthenticated: AuthState = {
  isAuthenticated: false,
  kind: AuthStateKind.Unauthenticated,
}

export const Authenticated: AuthState = {
  isAuthenticated: true,
  kind: AuthStateKind.Authenticated,
}
