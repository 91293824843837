import * as React from 'react'

const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={14} width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.047.735a1.333 1.333 0 012.016 1.738l-.11.127-10.43 10.666a1.334 1.334 0 01-1.757.132l-.127-.109L1.07 7.864a1.333 1.333 0 011.734-2.02l.127.11 4.615 4.496L17.047.735z"
      fill="#128279"
    />
  </svg>
)

export default CheckIcon
