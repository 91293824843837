import * as React from 'react'

import {Authenticated, Authenticating, AuthState, Unauthenticated} from 'constants/authState'

interface IUserResponse {
  country: string
  email: string
  firstName: string
  fullName?: string
  id?: number
  isPatient: boolean
  isPhysician: boolean
  isVerified: boolean
  lastName: string
  organization?: string
  role: string
}

export type TUser = Required<IUserResponse>

interface IGetUserAuthenticationStatus {
  onIsAuthenticated?: (user: TUser) => void
}

const useUserAuthenticationStatus = ({onIsAuthenticated}: IGetUserAuthenticationStatus) => {
  const [authState, setAuthState] = React.useState<AuthState>(Authenticating)
  const [user, setUser] = React.useState<TUser | null>(null)

  //check if user is authenticated
  const loadAuth = React.useCallback(async () => {
    const response: IUserResponse = await (await fetch(`${process.env.STARGATE_HOST}/marcom/user/`)).json()
    if (response.id) {
      // If `response.id` is present, that means that the user has been
      // authenticated, and, therefore, the response type is `TUser`
      const user = response as TUser
      // window user should be set to isAuthenticated: true for CartContext.
      // CartContext has authentication check for isAuthenticated.
      // window.user = {isAuthenticated: true}
      if (onIsAuthenticated) onIsAuthenticated(user)
      setAuthState(Authenticated)
      setUser(user)
    } else {
      setAuthState(Unauthenticated)
    }
  }, [])
  React.useEffect(() => {
    loadAuth()
  }, [loadAuth])

  return {authState, user}
}

export default useUserAuthenticationStatus
