import * as React from 'react'

import {usePanelCardQuery} from 'hooks/useConfiguredQuery.hooks'
import getComponentFriendlyPanelDataFromAPIData from 'utils/handleSpecialCases/getComponentFriendlyPanelDataFromAPIData'

import {IOptionalTestData, IPanelData} from './types'

function useGetPanelPrimaryAndTestData(panelCode: string) {
  const {data: panelAPIData, isError: isError} = usePanelCardQuery(panelCode)
  const [primaryPanelData, setPrimaryPanelData] = React.useState<IPanelData>()
  const [optionalTestList, setOptionalTestList] = React.useState<IOptionalTestData[]>([])
  const getPanelData = React.useCallback(async () => {
    if (panelAPIData?.panel) {
      const {primaryPanelData, optionalTestsData} = getComponentFriendlyPanelDataFromAPIData(
        panelAPIData.panel,
        panelAPIData.panel.optTests,
      )

      setPrimaryPanelData(primaryPanelData)
      if (optionalTestsData) {
        setOptionalTestList(optionalTestsData)
      }
    }
  }, [panelAPIData?.panel])

  React.useEffect(() => {
    getPanelData() //should be triggered only on componentDidMount
    return () => {
      setPrimaryPanelData(undefined)
      setOptionalTestList([])
    }
  }, [getPanelData])

  return {
    isError,
    optionalTestList,
    primaryPanelData,
  }
}

export default useGetPanelPrimaryAndTestData
