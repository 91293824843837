export const CONFIRM_ADD_TO_CART_ANALYTICS_EVENT = 'confirm add to cart from HCP test catalog'
export const ADD_TO_CART_ANALYTICS_EVENT = 'click add to cart from HCP test catalog'
export const ENTER_SEARCH_QUERY_ANALYTICS_EVENT = 'enter search query in main category genes search bar'

export const SEE_PANEL_DETAIL_ANALYTICS_EVENT = 'click to see panel details from test catalog main category page'
export const HOVER_OVER_ORDERING_TAG = 'hover over ordering tag on main category page'

// main category page
export const VIEW_MAIN_CATEGORY_PAGE = 'view main category page'

// panel detail page
export const VIEW_PANEL_PAGE = 'view panel page'

// gene page
export const VIEW_GENE_PAGE = 'view gene page'
