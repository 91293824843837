import * as React from 'react'
import {noop} from 'lodash'

import {
  GeneTestForPanelDetailedPageDto,
  OptTestForPanelDetailedPageDto,
  PanelDetailedPageDto,
  PanelDetailedPageDtoAggregationTypeEnum,
} from '@invitae/stargate'

import {IPanelData} from 'hooks/types'
import {IHandlePanelCardsResult} from 'hooks/useHandlePanelCards.hooks'

export interface IGeneTest extends GeneTestForPanelDetailedPageDto {
  isSelected?: boolean
}

export interface IOptTest extends Omit<OptTestForPanelDetailedPageDto, 'geneTests'> {
  geneTests?: IGeneTest[]
}
export interface IExtendedPanelDetails extends PanelDetailedPageDto {
  geneTests?: IGeneTest[]
  optTests?: IOptTest[]
}

export const defaultPanelDetailsContext: PanelDetailedPageDto = {
  aggregationType: PanelDetailedPageDtoAggregationTypeEnum.Group,
  alias: '',
  alternateSpecimens: '',
  assayInformation: '',
  categories: [],
  clinicalDescription: '',
  code: '',
  description: '',
  disordersTested: '',
  geneTests: [],
  metaDescription: '',
  name: '',
  optTests: [],
  preferredSpecimen: '',
  productType: '',
  tags: [],
  testSensitivity: '',
  totalGenesCount: 0,
  turnaroundTime: '',
}

interface IPanelDetailsExtendedContext extends IHandlePanelCardsResult {
  isCustomAssayEnabled: boolean
}

export type IPanelDetailsContext = Omit<
  IPanelDetailsExtendedContext,
  'resetSelectedItems' | 'sectionHeading' | 'setIsHoverOverTags'
>

export const defaultPanelDetailsContextValue = {
  cleanPanelCode: '',
  handleAddButtonClick: async () => noop(),
  handleSelectedPanel: () => noop(),
  isAddonProvided: false,
  isCustomAssayEnabled: false,
  isGeneCountEnabled: () => true,
  isNIPSMicrodeletionSyndromesAddon: () => true,
  onGeneSelect: () => noop(),
  optionalTestsData: [],
  primaryPanelData: {} as IPanelData,
  selectedPanels: [],
  showAdded: false,
  showNameOnOrderTest: () => false,
  totalGenesCount: 0,
  unselectedGenes: [],
}

const PanelDetailsContext = React.createContext<IPanelDetailsContext>(defaultPanelDetailsContextValue)

export default PanelDetailsContext
