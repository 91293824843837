import * as React from 'react'
import {debounce} from 'lodash'

import {NvHeading, NvSpinner} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'

import SearchInput from 'components/SearchInput'
import {ENTER_SEARCH_QUERY_ANALYTICS_EVENT} from 'constants/analytics'
import {useGetMainCategoryFrequentlyOrderedGenes} from 'hooks/useGetMainCategoryFrequentlyOrderedGenes'
import {useGetMainCategoryGenes} from 'hooks/useGetMainCategoryGenes'

import CategorySideMenuWrapper from '../CategorySideMenu/CategorySideMenuWrapper'
import GenesTabItem from './GenesTabItem'

import styles from './GenesTab.module.scss'

export interface IGenesTab {
  categoryId: string
  categoryName: string
  frequentlyOrderedGeneIds?: string[]
}

const mainCategoryWord = 'main category'
const SEARCH_QUERY_DEBOUNCE = 300

const GenesTab = ({categoryId, frequentlyOrderedGeneIds, categoryName}: IGenesTab) => {
  const [keyword, setKeyword] = React.useState('')
  const {logEvent} = useAnalyticsQueue()

  const {loadingFrequentlyOrderedGenes, frequentlyOrderedGenes} =
    useGetMainCategoryFrequentlyOrderedGenes(frequentlyOrderedGeneIds)

  const {loadingGenes, genes} = useGetMainCategoryGenes(categoryId, keyword)

  const onClear = () => setKeyword('')

  const isLoading = React.useMemo(
    () => (keyword ? loadingGenes : loadingFrequentlyOrderedGenes),
    [keyword, loadingGenes, loadingFrequentlyOrderedGenes],
  )

  const genesToShow = React.useMemo(
    () => (keyword ? genes : frequentlyOrderedGenes),
    [frequentlyOrderedGenes, genes, keyword],
  )

  const found = React.useMemo(() => Boolean(!isLoading && genesToShow.length), [genesToShow.length, isLoading])

  const foundGenesHeading = React.useMemo(
    () => (keyword ? `Results for ${keyword.toUpperCase()}` : 'Frequently ordered genes'),
    [keyword],
  )

  const notFoundGenesHeading = React.useMemo(
    () =>
      keyword
        ? `We couldn’t find a match for “${keyword}”. Please try another search`
        : 'No frequently ordered genes found',
    [keyword],
  )

  const handleSearchQueryAnalyticsEvent = React.useCallback(
    (searchQuery: string) => {
      logEvent({
        eventName: ENTER_SEARCH_QUERY_ANALYTICS_EVENT,
        eventProperties: {
          query: searchQuery,
        },
      })
    },
    [logEvent],
  )

  const debounceSearchAnalyticsLog = React.useMemo(
    () => debounce(handleSearchQueryAnalyticsEvent, SEARCH_QUERY_DEBOUNCE),
    [handleSearchQueryAnalyticsEvent],
  )

  const onChange = React.useCallback(
    (value: string) => {
      setKeyword(value)
      debounceSearchAnalyticsLog(value)
    },
    [debounceSearchAnalyticsLog],
  )

  const debounceSearch = React.useMemo(() => debounce(onChange, SEARCH_QUERY_DEBOUNCE), [onChange])

  const handleSearch = React.useCallback(
    (value: string) => {
      debounceSearch(value)
    },
    [debounceSearch],
  )

  const mainCategoryTitle = React.useMemo(
    () => (categoryName.indexOf(mainCategoryWord) >= 0 ? categoryName.split(mainCategoryWord)[0] : categoryName),
    [categoryName],
  )
  return (
    <CategorySideMenuWrapper menuList={[]}>
      <div className={styles.root}>
        <NvHeading className={styles.title} level={4}>
          Browse frequently ordered genes or search for any {mainCategoryTitle} gene to build your own panel.
        </NvHeading>
        <SearchInput
          className={styles.geneSearchInput}
          clearable
          onChange={handleSearch}
          onClear={onClear}
          placeholder="Search by gene name or alias"
        />
        {isLoading && <NvSpinner isLoading />}

        {!isLoading && (
          <>
            {found ? (
              <NvHeading data-testid="found-genes-heading" element="h2" level={3}>
                {foundGenesHeading}
              </NvHeading>
            ) : (
              ''
            )}

            <div className={styles.genesWrapper}>
              {found ? (
                genesToShow.map(gene => (
                  <GenesTabItem key={gene.code} keyword={keyword} {...gene} mainCategoryTitle={mainCategoryTitle} />
                ))
              ) : (
                <NvHeading data-testid="not-found-genes-heading" element="h3" level={3}>
                  {notFoundGenesHeading}
                </NvHeading>
              )}
            </div>
          </>
        )}
      </div>
    </CategorySideMenuWrapper>
  )
}

export default GenesTab
