import * as React from 'react'

import {ToggleContext} from '@invitae/nucleobase'

import MainCategoryContext from 'components/MainCategory/MainCategoryContext'
import {FIBROBLAST_CELL_CULTURE_CODE, REPRODUCTIVE_KARYOTYPE_CODE} from 'constants/specialCases'

// filter a list of categories for side menu in Main Category pages
// checks for onn/off feature flags from launch darkly
// checks for categories without panels
export const useGetFilteredSubcategories = () => {
  const {cxReproductiveKaryotype} = React.useContext(ToggleContext)

  const {subCategories} = React.useContext(MainCategoryContext).mainCategory
  const [filteredSubCategories, setFilteredSubCategories] = React.useState(subCategories)

  React.useEffect(() => {
    const subcategoryList = subCategories?.filter(item => {
      if (item.code === FIBROBLAST_CELL_CULTURE_CODE) return false
      if (item.code === REPRODUCTIVE_KARYOTYPE_CODE && !cxReproductiveKaryotype) return false
      return (item?.panelIds ?? []).length > 0
    })
    setFilteredSubCategories(subcategoryList)
  }, [subCategories, cxReproductiveKaryotype])

  return {filteredSubCategories}
}
