export const DOT_ICON = '•'

const CALENDAR_IDENTIFIER = 'calendar'
const WEEKS_IDENTIFIER = 'weeks'
const OPEN_PARENTHESIS_IDENTIFIER = '('
const TURNAROUND_TIME_TEXT = 'turnaround time'

const MAX_TURNAROUND_TIME_DAYS = 21
const MAX_TURNAROUND_TIME_WEEKS = 3

/***
 * @param turnaroundTime - turnaroundTime string from raw data: "14 - 21 calendar days (14 days on average)"
 * @returns "14 - 21 days turnaround time"
 *
 * 1) removes calendar with .replace()
 * 2) removes all data after first bracket "(" to remove average days information
 * 3) adds "turnaround time" at the end
 * */
export const getTurnaroundTimeDescription = (turnaroundTime: string) => {
  let result = turnaroundTime
  if (
    turnaroundTime &&
    turnaroundTime.includes(CALENDAR_IDENTIFIER) &&
    turnaroundTime.includes(OPEN_PARENTHESIS_IDENTIFIER)
  ) {
    const removedCalendar = turnaroundTime.replace(CALENDAR_IDENTIFIER, '')
    const removedAverage = removedCalendar.substring(0, removedCalendar.indexOf(OPEN_PARENTHESIS_IDENTIFIER))
    result = removedAverage.concat(TURNAROUND_TIME_TEXT)
  }

  return result.length > 0 ? `${DOT_ICON} ${result}` : result
}

/**
 *
 * @param turnaroundTimeDescription - cleaned out data "10–21 days turnaround time"
 * The time range dash may be either a standard dash or an en-dash (concept mapper uses both)
 * @returns boolean -> true if the turnaround time if faster than MAX_TURNAROUND_TIME
 *
 * 1) get list of numbers in the string with .match [10, 21]
 * 2) check if the higher number in the range is less than MAX_TURNAROUND_TIME days
 */
export const getShouldShowFastTurnaroundTag = (turnaroundTimeDescription: string) => {
  // concept mapper returns both dash and en-dash as delimiters
  // these dashes may look the same, but they are different characters
  const timeRangeRegex = /\d+([-–])\d+/g
  const isRange = timeRangeRegex.test(turnaroundTimeDescription)
  const isMeasuredInWeeks = turnaroundTimeDescription.toLowerCase().includes(WEEKS_IDENTIFIER)
  const [lowerBound, upperBound] = (turnaroundTimeDescription.match(/\d+/g) ?? []).map(Number)
  const duration = isRange ? upperBound : lowerBound
  const maxTurnaroundTime = isMeasuredInWeeks ? MAX_TURNAROUND_TIME_WEEKS : MAX_TURNAROUND_TIME_DAYS
  return !!duration && duration < maxTurnaroundTime
}
