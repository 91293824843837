import React, {useEffect} from 'react'

import {FeatureFlagStateDto} from '@invitae/stargate'

import FeatureFlagsAPI from 'api/featureFlagsApi'
import {FEATURE_FLAGS} from 'constants/featureFlags'

import {TUser} from './useUserAuthenticationStatus.hooks'

export const useFeatureFlags = (user: TUser | null) => {
  const [featureFlags, setFeatureFlags] = React.useState<FeatureFlagStateDto[] | null>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  useEffect(() => {
    const fetchFlags = async () => {
      const data = await new FeatureFlagsAPI().getFeatureFlags(FEATURE_FLAGS, user)
      setFeatureFlags(data.featureFlags)
      setIsLoading(false)
    }

    fetchFlags()
  }, [user])

  const getFeatureFlagValueByName = (name: string): boolean => {
    if (!featureFlags) return false
    return featureFlags.find(featureFlag => featureFlag.name === name)?.enabled || false
  }

  return {featureFlags, getFeatureFlagValueByName, isLoading}
}
