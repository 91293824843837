import {Entry} from 'contentful'

import {IPageFields, ITestCatalogMainCategorySection} from '@invitae/contentful-types'

export const SECTION_MAIN_CATEGORY_TEST_CATALOG = 'testCatalogMainCategorySection'

export const mainCategoryPaths = [
  'providers/test-catalog/cardiology',
  'providers/test-catalog/dermatology',
  'providers/test-catalog/developmental-disorders',
  'providers/test-catalog/endocrinology',
  'providers/test-catalog/hematology',
  'providers/test-catalog/hereditary-cancer',
  'providers/test-catalog/immunology',
  'providers/test-catalog/metabolic-disorders-and-newborn-screening',
  'providers/test-catalog/nephrology',
  'providers/test-catalog/neurology',
  'providers/test-catalog/ophthalmology',
  'providers/test-catalog/pediatric-genetics',
  'providers/test-catalog/carrier-screening',
  'providers/test-catalog/non-invasive-prenatal-screening',
  'providers/test-catalog/preimplantation-genetic-testing',
  'providers/test-catalog/pregnancy-loss-and-infertility',
  'providers/test-catalog/prenatal-diagnosis',
  'providers/test-catalog/proactive-health',
  'providers/test-catalog/exome',
  'providers/test-catalog/pharmacogenomics',
  'providers/test-catalog/pregnancy-loss-testing',
  'providers/test-catalog/minimal-residual-disease',
  'providers/test-catalog/personalized-cancer-monitoring',
]

export const pureContentfulPagesPaths = [
  'providers/test-catalog',
  // eslint-disable-next-line spellcheck/spell-checker
  'providers/test-catalog/medicare-australia',
  'providers/test-catalog/oncology',
  'providers/test-catalog/reproductive',
  'providers/test-catalog/pediatric-and-rare-diseases',
  'providers/test-catalog/select-a-test',
]

export const getMainCategoryCode = async (page: Entry<IPageFields>): Promise<string> => {
  // find the mainCategorySection
  const mainCategorySection = page.fields.sections.find(
    section => section.sys.contentType.sys.id === SECTION_MAIN_CATEGORY_TEST_CATALOG,
  )
  if (mainCategorySection) {
    return Promise.resolve((mainCategorySection as ITestCatalogMainCategorySection).fields.mainCategoryPrCode)
  }
  throw new Error('Main category code not found within the page')
}

export const cleanUpUrlFromParams = (url: string) =>
  url.indexOf('?') !== -1 ? url.substring(0, url.indexOf('?')) : url

// add space before/after "/"
// eslint-disable-next-line spellcheck/spell-checker
// handle special cases for Combined T / B cell deficiencies and RASopathies (Noonan spectrum disorders) categories
export const formatSubcategoryTitle = (title: string) => {
  // eslint-disable-next-line spellcheck/spell-checker
  if (title.includes('RASopathies')) {
    return title.slice(0, 14) + title.slice(14).toLowerCase()
  }
  if (title.includes('T/B')) {
    return title.replace('T/B', 'T / B')
  }
  if (title.includes('t/b')) {
    return title.replace('t/b', 'T / B')
  }
  return title.replace(/\//g, ' / ')
}
