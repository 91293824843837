import React from 'react'

import {CircleVariant, ColorVariant, IconCarrier, IconNips, SizeVariant} from '@invitae/iconograph-react'

import {SPRING} from 'constants/colors'

const iconCards = [
  {
    heading: 'Carrier screening',
    icon: (
      <IconCarrier
        circle={CircleVariant.no}
        color={ColorVariant.none}
        currentColor
        size={SizeVariant.medium}
        style={{
          color: SPRING,
        }}
      />
    ),
    url: '/physician/category/CAT000239',
  },
  {
    heading: 'Non-invasive prenatal screening',
    icon: (
      <IconNips
        circle={CircleVariant.no}
        color={ColorVariant.none}
        currentColor
        size={SizeVariant.medium}
        style={{
          color: SPRING,
        }}
      />
    ),
    url: '/providers/test-catalog/non-invasive-prenatal-screening',
  },
]

export default iconCards
