import LogRocket from 'logrocket'

import {TUser} from 'hooks/useUserAuthenticationStatus.hooks'

import {isProdOrStg, isSyntheticTestUser} from './utils'

/**
 * Initializes Logrocket integration and tracking
 */
export const initializeLogRocket = (user: TUser) => {
  if (!isProdOrStg() || isSyntheticTestUser(user.email)) {
    console.warn('Skipping Logrocket initialization') // eslint-disable-line no-console
    return
  }

  LogRocket.init(`invitae/ordering-fulfillment-${process.env.INVITAE_ENV}`)

  LogRocket.identify(user.id.toString(), {
    buildVersion: process.env.COMMIT_TAG || '',
    email: user.email,
    name: user.fullName,
  })
}

export default initializeLogRocket
