// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable spellcheck/spell-checker */
import React from 'react'
import classNames from 'classnames'
import {PediatricAndRareDiseasesTestCodes as testCodes} from 'common/testCodes'
import Link from 'next/link'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {NvButton, NvSpinner, NvTooltip} from '@invitae/ids-react'
import {useAnalyticsQueue, useTextCopy} from '@invitae/nucleobase'
import {CartContext} from '@invitae/physician-cart'

import Breadcrumbs from 'components/Breadcrumbs'
import GeneListTooltip from 'components/GeneListTooltip'
import {ADD_TO_CART_ANALYTICS_EVENT} from 'constants/analytics'
import {Authenticated, Authenticating} from 'constants/authState'
import {TextCopyType} from 'constants/textCopies'
import {usePanelQuery} from 'hooks/useConfiguredQuery.hooks'
import {PANEL, useGetLinkDetailPage} from 'hooks/useGetLinkToDetailPage'

import styles from './PediatricAndRareDiseasesComponentBlock.module.scss'

enum ComponentBlockStatus {
  INITIAL = 'initial',
  DONE = 'done',
}

const PediatricAndRareDiseasesComponentBlock = () => {
  const {authState} = React.useContext(UserAuthStatusContext)
  const {addToCart} = React.useContext(CartContext)
  const [isAddingToCart, setAddingToCart] = React.useState(false)
  const [status, setStatus] = React.useState<ComponentBlockStatus>(ComponentBlockStatus.INITIAL)
  const {logEvent} = useAnalyticsQueue()
  const {getText} = useTextCopy<TextCopyType<'PediatricAndRareDiseasesComponentBlock'>>()

  const {isLoading: isLoading1, data: panel1} = usePanelQuery(testCodes.INVITAE_NEURODEVELOPMENTAL_DISORDERS_PANEL)
  const {isLoading: isLoading2, data: panel2} = usePanelQuery(testCodes.INVITAE_CHROMOSOMAL_MICROARRAY_ANALYSIS)
  const {isLoading: isLoading3, data: panel3} = usePanelQuery(testCodes.INVITAE_FRAGILE_X_RELATED_DISORDERS_DIAGNOSTIC)

  const isLoading = React.useMemo(() => isLoading1 || isLoading2 || isLoading3, [isLoading1, isLoading2, isLoading3])
  const panels = React.useMemo(
    () => (isLoading || !panel1 || !panel2 || !panel3 ? [] : [panel1, panel2, panel3]),
    [panel1, panel2, panel3, isLoading],
  )

  const nddPanel = panels[0]?.fields
  const cmaPanel = panels[1]?.fields
  const fxsPanel = panels[2]?.fields

  const {panelDetailLink: nddPanelLink} = useGetLinkDetailPage(
    PANEL,
    testCodes.INVITAE_NEURODEVELOPMENTAL_DISORDERS_PANEL,
  )

  const {panelDetailLink: cmaPanelLink} = useGetLinkDetailPage(PANEL, testCodes.INVITAE_CHROMOSOMAL_MICROARRAY_ANALYSIS)

  const {panelDetailLink: fxsPanelLink} = useGetLinkDetailPage(
    PANEL,
    testCodes.INVITAE_FRAGILE_X_RELATED_DISORDERS_DIAGNOSTIC,
  )

  const redirectToSignIn = React.useCallback(() => {
    window?.location?.assign?.(`${process.env.SIGN_IN_PAGE}?next=/providers/test-catalog/pediatric-and-rare-diseases`)
  }, [])

  const logAddToCartEvent = React.useCallback(() => {
    logEvent({
      eventName: ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'current page': window?.location?.href,
        'Panel Name': getText('packageName'),
      },
    })
  }, [logEvent, getText])

  const handleClick = React.useCallback(async () => {
    if (isLoading || isAddingToCart || status === ComponentBlockStatus.DONE || authState === Authenticating) {
      return
    }
    if (status === ComponentBlockStatus.INITIAL) {
      if (authState === Authenticated) {
        setAddingToCart(true)
        const panelCodes = [nddPanel.code, cmaPanel.code, fxsPanel.code]
        await addToCart(panelCodes, undefined, [{codes: panelCodes, parentId: '', parentType: ''}])

        logAddToCartEvent()

        setAddingToCart(false)
        setStatus(ComponentBlockStatus.DONE)
      } else {
        redirectToSignIn()
      }
    }
  }, [
    addToCart,
    isAddingToCart,
    isLoading,
    authState,
    logAddToCartEvent,
    cmaPanel,
    nddPanel,
    fxsPanel,
    redirectToSignIn,
    status,
  ])

  if (isLoading) {
    return <NvSpinner isLoading />
  }

  return (
    <>
      <Breadcrumbs isGray />
      <section className={classNames(styles.root, 'nv-container')} id="pediatric-and-rare-diseases">
        <div className={styles.content}>
          <h2 className={classNames('nv-mh2', styles.title)}>{getText('title')}</h2>
          <div className={styles.packageCard}>
            <div className={styles.imageContainerMobile}>
              <div className={styles.imageWrapper}>
                <img alt="" src={getText('imageUrl')} />
              </div>
            </div>
            <div className={styles.imageRow}>
              <div>
                <div className={styles.titleRowContainer}>
                  <h3 className={styles.packageCardTitle}>{getText('packageName')}</h3>
                </div>
                <p>{getText('description')}</p>
              </div>
              <div className={styles.imageContainerDesktop}>
                <div className={styles.imageWrapper}>
                  <img alt="" className={styles.image} src={getText('imageUrl')} />
                </div>
              </div>
            </div>
            {panels.length > 0 && (
              <div className={styles.packageCardTests}>
                <div className={styles.panelNameContainer}>
                  <span aria-hidden="true" data-testid="ndd-panel-title" role="button">
                    <Link href={nddPanelLink}>{nddPanel.name}</Link>
                  </span>
                  <NvTooltip
                    destroyTooltipOnHide
                    limitedWidth={false}
                    overlayContent={
                      <GeneListTooltip
                        primaryPanelData={{
                          geneList: nddPanel.geneTests.fields.geneTests.map((test: {[x: string]: any}) => ({
                            code: test.fields.geneTest.code,
                            name: test.fields.geneTest.name,
                          })),
                          panelCode: nddPanel.code,
                          title: nddPanel.name || '',
                          totalGenesCount: nddPanel.totalGenesCount || 0,
                        }}
                        selectedPanels={panels.map(panel => panel.fields.code)}
                      />
                    }
                    showCloseButtonTop
                    trigger="click"
                  >
                    <button
                      className={classNames('nv-plain-action', styles.geneCount)}
                      data-testid="ndd-panel-gene-count"
                      type="button"
                    >
                      {nddPanel.totalGenesCount} {getText('genes')}
                    </button>
                  </NvTooltip>
                </div>
                <div className={styles.panelNameContainer}>
                  <span aria-hidden="true" data-testid="cma-panel-title" role="button">
                    <Link href={cmaPanelLink}>{cmaPanel.name}</Link>
                  </span>
                </div>
                <div className={styles.panelNameContainer}>
                  <span aria-hidden="true" data-testid="fxs-panel-title" role="button">
                    <Link href={fxsPanelLink}>{fxsPanel.name}</Link>
                  </span>
                </div>
              </div>
            )}
            <div className={styles.buttonContainer}>
              <NvButton
                className={classNames(styles.addPackageButton, {
                  [styles.disabled]:
                    isLoading || isAddingToCart || status === ComponentBlockStatus.DONE || authState === Authenticating,
                })}
                disabled={
                  isLoading || isAddingToCart || status === ComponentBlockStatus.DONE || authState === Authenticating
                }
                onClick={handleClick}
                size="medium"
              >
                {status === ComponentBlockStatus.INITIAL && getText('initialStatus')}
                {status === ComponentBlockStatus.DONE && getText('doneStatus')}
              </NvButton>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PediatricAndRareDiseasesComponentBlock
