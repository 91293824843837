import * as React from 'react'

import {
  CircleVariant,
  ColorVariant,
  IconCancer,
  IconPediatrics,
  IconWoman,
  SizeVariant,
} from '@invitae/iconograph-react'

import {WHITE} from 'constants/colors'

const hubHeroCards = [
  {
    backgroundClassName: 'cardImageBackgroundFirst',
    header: 'Oncology',
    icon: (
      <IconCancer
        circle={CircleVariant.yes}
        color={ColorVariant.none}
        currentColor
        size={SizeVariant.small}
        style={{
          color: WHITE,
        }}
      />
    ),
    id: 1,
    image: 'https://invitae.imgix.net/web/test-catalog/21_Alvin_4324.png?q=20&auto=format',
    imageClassName: 'cardImagePositionFirst',
    paragraph: 'Get genetic information that can inform treatment and clinical trial opportunities for your patients.',
    url: '/providers/test-catalog/oncology',
  },
  {
    backgroundClassName: 'cardImageBackgroundSecond',
    header: 'Women’s health',
    icon: (
      <IconWoman
        circle={CircleVariant.yes}
        color={ColorVariant.none}
        currentColor
        size={SizeVariant.small}
        style={{
          color: WHITE,
        }}
      />
    ),
    id: 2,
    image: 'https://invitae.imgix.net/web/test-catalog/11_Katheryn_Jessica_1613.png?q=40&auto=format',
    imageClassName: 'cardImagePositionSecond',
    paragraph: 'Get genetic insights to better understand your patient’s cancer risk.',
    url: '/providers/test-catalog/hereditary-cancer',
  },
  {
    backgroundClassName: 'cardImageBackgroundThird',
    header: 'Pediatric & Rare diseases',
    icon: (
      <IconPediatrics
        circle={CircleVariant.yes}
        color={ColorVariant.none}
        currentColor
        size={SizeVariant.small}
        style={{
          color: WHITE,
        }}
      />
    ),
    id: 3,
    image: 'https://invitae.imgix.net/web/test-catalog/1_Pediatric_24May24.png?q=60&auto=format',
    imageClassName: 'cardImagePositionThird',
    paragraph:
      'Get genetic information for patients with pediatric onset of rare diseases to inform prognosis, treatments, and next steps.',
    url: '/providers/test-catalog/pediatric-and-rare-diseases',
  },
]

export default hubHeroCards
