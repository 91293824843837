import React from 'react'
import {ReproductiveTestCodes as testCodes} from 'common/testCodes'

import {NvSpinner} from '@invitae/ids-react'
import {useTextCopy} from '@invitae/nucleobase'

import ReproductiveBaseComponentBlock from 'components/ReproductiveBaseComponentBlock/ReproductiveBaseComponentBlock'
import {TextCopyType} from 'constants/textCopies'
import {usePanelQuery} from 'hooks/useConfiguredQuery.hooks'

import iconCards from './IconCards'

import styles from './ConceivingComponentBlock.module.scss'

const ConceivingComponentBlock = () => {
  const {getText} = useTextCopy<TextCopyType<'ConceivingComponentBlock'>>()

  const {isLoading: isLoading1, data: panel1} = usePanelQuery(testCodes.INVITAE_COMPREHENSIVE_CARRIER_SCREEN)
  const {isLoading: isLoading2, data: panel2} = usePanelQuery(testCodes.INVITAE_GENETIC_HEALTH_SCREEN)

  const isLoading = React.useMemo(() => isLoading1 || isLoading2, [isLoading1, isLoading2])
  const panels = React.useMemo(
    () => (isLoading || !panel1 || !panel2 ? [] : [panel1, panel2]),
    [panel1, panel2, isLoading],
  )

  if (isLoading) return <NvSpinner isLoading />
  return (
    <ReproductiveBaseComponentBlock
      description={getText('description')}
      iconCards={iconCards}
      imageClassName={styles.image}
      imgUrl={getText('imageUrl')}
      isLoading={isLoading}
      packageName={getText('packageName')}
      packageType={getText('packageType')}
      panels={panels}
      title={getText('title')}
    />
  )
}

export default ConceivingComponentBlock
