import * as React from 'react'
import {noop} from 'lodash'

type TCardIndex = number

export const PanelCardsItemIndexContext = React.createContext<TCardIndex>(0)
export interface IPanelCardsItemSelectedContextProps {
  addonOpenedId: string
  setSelectedAddon: (cardId: string) => void
  sectionHeading: string
  tooltipOpenedId: string
  setSelectedTooltip: (cardId: string) => void
  //used when panels are fetched from stargate
  setPanelLoadedState?: (index: number) => void
}

export const PanelCardsItemSelectedContext = React.createContext<IPanelCardsItemSelectedContextProps>({
  addonOpenedId: '1',
  sectionHeading: '',
  setSelectedAddon: () => noop,
  setSelectedTooltip: () => noop,
  tooltipOpenedId: '1',
})
