import * as React from 'react'

import {Authenticating, AuthState} from 'constants/authState'

interface IUserAuthStatusContextProps {
  /**
   * This prop can have three states depending on useUserAuthenticationStatus hook completion.
   *
   * @example
   * Authenticating  - user is being authenticated (prop has not been set in hook yet),
   * Unauthenticated - user is not authenticated,
   * Authenticated   - user is authenticated.
   */
  authState: AuthState
}

export const UserAuthStatusContext = React.createContext<IUserAuthStatusContextProps>({
  authState: Authenticating,
})
