import * as React from 'react'

import {TestCatalogSearch} from '@invitae/header-footer'
import {ToggleContext, useTextCopy} from '@invitae/nucleobase'

import {TextCopyType} from 'constants/textCopies'

import HubHeroCard from './HubHeroCard'
import {default as hubHeroCards} from './hubHeroCards'
import HubHeroShape from './HubHeroShape'

import styles from './HubHero.module.scss'

const HubHero = () => {
  const {isLoading} = React.useContext(ToggleContext)

  const {getText} = useTextCopy<TextCopyType<'HubHero'>>()

  return isLoading ? null : (
    <div className={styles.root} data-cy="hubHero">
      <div className={styles.shapesWrapper}>
        <HubHeroShape className={styles.shape} />
      </div>
      <h1 className={styles.header} data-cy="hubHero_header">
        {getText('title')}
      </h1>
      <div className={styles.searchWrapper}>
        <TestCatalogSearch
          className={{root: styles.searchRoot}}
          defaultSuggestions={[]}
          isDefaultSuggestionsEnabled={false}
          resultListType="popup"
          searchQueryAnalyticsEvent={{eventName: getText('searchQueryAnalyticsEvent')}}
          searchResultLinkClickAnalyticsEvent={{eventName: getText('clickSearchResultLinkAnalyticsEvent')}}
        />
      </div>
      <div className={styles.cardsWrapper}>
        <ul className={styles.cardsInnerWrapper} data-cy="hubHero_cards">
          {hubHeroCards.map(item => (
            <li key={item.id}>
              <HubHeroCard {...item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default HubHero
