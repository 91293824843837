import * as React from 'react'
import deepcopy from 'ts-deepcopy'

import {CategoryV2Dto} from '@invitae/stargate'

import {SponsoredTestingType} from 'api/types'

interface MainCategoryContextType {
  mainCategory: CategoryV2Dto
  sponsoredTestingData: SponsoredTestingType
}

const defaultMainCategoryContext: MainCategoryContextType = {
  mainCategory: {
    code: '',
    conceptType: '',
    description: '',
    name: '',
    panels: [],
    subCategories: [],
    totalGenesCount: 0,
  },
  sponsoredTestingData: {},
}

export const copyDefaultContext = () => deepcopy<MainCategoryContextType>(defaultMainCategoryContext)

const MainCategoryContext = React.createContext<MainCategoryContextType>(copyDefaultContext())

export default MainCategoryContext
