import React from 'react'

import {fetchMainCategoryFrequentlyOrderedGenes} from 'api/gene/frequentlyOrderedGenesApi'
import {IGenesTabItem} from 'components/CategoryTabSection/GenesTab/GenesTabItem'

export const useGetMainCategoryFrequentlyOrderedGenes = (frequentlyOrderedGeneIds?: string[]) => {
  const [loadingFrequentlyOrderedGenes, setLoadingFrequentlyOrderedGenes] = React.useState(false)
  const [frequentlyOrderedGenes, setFrequentlyOrderedGenes] = React.useState<IGenesTabItem[]>([])

  const getFrequentlyOrderedGenes = React.useCallback(async () => {
    try {
      if (!frequentlyOrderedGeneIds) {
        setFrequentlyOrderedGenes([])
        return
      }

      setLoadingFrequentlyOrderedGenes(true)
      const frequentlyOrderedGenes = await fetchMainCategoryFrequentlyOrderedGenes(frequentlyOrderedGeneIds)
      setFrequentlyOrderedGenes(
        frequentlyOrderedGenes.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase())),
      )
      setLoadingFrequentlyOrderedGenes(false)
    } catch {
      setLoadingFrequentlyOrderedGenes(false)
    }
  }, [frequentlyOrderedGeneIds])

  React.useEffect(() => {
    getFrequentlyOrderedGenes()
  }, [frequentlyOrderedGeneIds, getFrequentlyOrderedGenes])

  return {frequentlyOrderedGenes, loadingFrequentlyOrderedGenes}
}
