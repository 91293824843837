import textile from 'textile-js'

import {cleanUpUrlFromParams} from 'components/MainCategory/utils'
import {PARTIAL_LINK_EXOME_CATEGORY} from 'constants/links'
import {OLD_PANEL_REGEX, TEST_REGEX} from 'constants/specialCases'
import {IPanelGene} from 'hooks/types'
import {checkIfGeneNameSpecialCase} from 'utils/utils'

// eslint-disable-next-line spellcheck/spell-checker
const extractHrefs = (htmlString: string) =>
  [...htmlString.matchAll(/href="([^"]+)"/g)].map(m => cleanUpUrlFromParams(m[1]))

const checkIfStringPanelUrl = (htmlString: string) =>
  // eslint-disable-next-line spellcheck/spell-checker
  extractHrefs(htmlString).some(url => url.match(OLD_PANEL_REGEX) || url.match(TEST_REGEX))

const parseAlternativeTest = (item: string) => {
  // eslint-disable-next-line spellcheck/spell-checker
  const hrefs = extractHrefs(item)
  // parse old and new formats of link to panel detail page
  // eslint-disable-next-line spellcheck/spell-checker
  if (hrefs.some(url => url.match(OLD_PANEL_REGEX))) {
    const splitUrl = item.split(/\//).filter(item => item && item.match(/\d+$/))
    return splitUrl[0]
    // eslint-disable-next-line spellcheck/spell-checker
  } else if (hrefs.some(url => url.match(TEST_REGEX))) {
    // eslint-disable-next-line spellcheck/spell-checker
    const testUrl = hrefs.find(url => url.match(TEST_REGEX))
    const testId = testUrl!.match(TEST_REGEX)![0].replace(/\D/g, '')

    return testId
  }

  return ''
}

// get alternative tests ids and description from string in format: ${subtextDescription} \n* "(external) ${testName}": ${link}
// link can be in 3 formats: '/*/tests/${id}/' or '*/test-${id}
export const getAlternativeTestsIdsAndSubtext = (alternativeTests: string) => {
  const textileParsed = textile.parse(alternativeTests)
  const isList = textileParsed.match('</ul>')
  let parsedAlternativeTestsText: string[] = []
  const categories: string[] = []
  if (isList) {
    parsedAlternativeTestsText = textileParsed
      // get all other tests sections
      .split('</ul>')
      .filter((item: string) => item.trim().length)
  } else if (textileParsed.match('<p>') && checkIfStringPanelUrl(textileParsed)) {
    parsedAlternativeTestsText = textileParsed
      // get all elements: tests and subtext
      .split('<p>')
      .filter((item: string) => item.trim().length)
  }

  const alternativePanels: {
    subtext: string
    alternativeTests: string[]
    categories: string[]
  }[] = []

  // if alternative panels are in list <ul></ul>
  if (isList) {
    parsedAlternativeTestsText.forEach((item: string) => {
      // get subtext and alternative tests texts
      const alternativeTest = item.split('<ul>')

      const alternativeTestsArray = alternativeTest[1]
        ? alternativeTest[1]
            .split('<li>')
            .map(item => {
              if (item.match(/category\/CAT(\d+)/) || item.match(PARTIAL_LINK_EXOME_CATEGORY)) {
                categories.push(item.split('</li>')[0])
              }
              return parseAlternativeTest(item)
            })
            .filter(item => item && item.length)
        : []

      alternativePanels.push({
        alternativeTests: alternativeTestsArray,
        categories,
        subtext: alternativeTest[0].trim(),
      })
    })
    // other cases: handled for <p></p>
  } else {
    parsedAlternativeTestsText.forEach((item: string) => {
      // get subtext and alternative tests texts
      const tests = []
      let subtext = ''

      const test = parseAlternativeTest(item)

      // if parsed paragraph does not have link to test then its subtext
      if (!test.length) subtext = item
      // in other case add test to array
      else tests.push(test)

      alternativePanels.push({
        alternativeTests: tests.filter(item => item.length),
        categories: [],
        subtext,
      })
    })
  }

  return alternativePanels
}

export const getUnselectedPanelGeneCountFromTotalUnselectedGeneList = (
  totalUnselectedGeneList: IPanelGene[],
  totalPanelGeneList: IPanelGene[],
) => {
  const panelUnselectedGene =
    totalUnselectedGeneList?.filter(item => totalPanelGeneList.some(panelGene => panelGene.name === item.name)) ?? []
  //counters should cover the double item count even though we have HBA1 and HBA2 or SMN1 and SMN2 as one item
  if (panelUnselectedGene.some(item => checkIfGeneNameSpecialCase(item.name))) {
    return panelUnselectedGene.length + 1
  }
  return panelUnselectedGene.length
}

export const getSelectedPanelGenesFromTotalUnselectedGeneList = (
  totalUnselectedGeneList: IPanelGene[],
  totalPanelGeneList: IPanelGene[],
) => {
  return (
    totalPanelGeneList.filter(item => !totalUnselectedGeneList.some(totalGene => totalGene.name === item.name)) ?? []
  )
}

export const getSelectedPanelGeneCountFromTotalUnselectedGeneList = (
  totalUnselectedGeneList: IPanelGene[],
  totalPanelGeneList: IPanelGene[],
) => {
  const panelSelectedGene = getSelectedPanelGenesFromTotalUnselectedGeneList(
    totalUnselectedGeneList,
    totalPanelGeneList,
  )
  //counters should cover the double item count even though we have HBA1 and HBA2 or SMN1 and SMN2 as one item
  if (panelSelectedGene.some(item => checkIfGeneNameSpecialCase(item.name))) {
    return panelSelectedGene.length + 1
  }
  return panelSelectedGene.length
}

export const onlyOneGeneSelectedRemained = (unselectedGenes: IPanelGene[], panelGeneList: IPanelGene[]) => {
  const unselectedAmount = panelGeneList.filter(panelGeneItem =>
    unselectedGenes.some(item => panelGeneItem.name === item.name),
  ).length
  return (unselectedAmount > 0 && unselectedAmount === panelGeneList.length - 1) || panelGeneList.length <= 1
}
