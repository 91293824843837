import * as React from 'react'
import classNames from 'classnames'
import CheckIcon from 'icons/CheckIcon'

import {NvCheckbox} from '@invitae/ids-react'

import styles from './PanelAddonItem.module.scss'

interface AddonItemProps {
  isPrimary?: boolean
  isRequired?: boolean
  isAddonSelected: boolean
  isGeneCountEnabled?: boolean
  onSelect?: (isSelected: boolean) => void
  title: string
  geneCount: number
}

const PanelAddonItem = ({
  isPrimary = false,
  isRequired = false,
  isAddonSelected,
  isGeneCountEnabled = true,
  onSelect,
  title,
  geneCount,
}: AddonItemProps) => {
  const geneCountInfo = React.useMemo(
    () => (geneCount ? `(${geneCount} ${geneCount === 1 ? 'gene' : 'genes'})` : ''),
    [geneCount],
  )

  return (
    <div className={classNames(styles.cardCheckbox, {[styles.cardCheckboxClickable]: !isPrimary && !isRequired})}>
      {isPrimary || isRequired ? (
        <div className={styles.requiredItemWrapper}>
          <CheckIcon className={styles.requiredItemIcon} />
          <p className={classNames(styles.checkboxTitle)}>
            {title} {isGeneCountEnabled && geneCountInfo}
          </p>
        </div>
      ) : (
        <>
          <NvCheckbox
            checked={isAddonSelected}
            className={styles.title}
            data-testid="panel-add-on-item"
            id={title}
            label={`${title} ${isGeneCountEnabled ? geneCountInfo : ''}`}
            onChange={() => onSelect && onSelect(!isAddonSelected)}
          />
        </>
      )}
    </div>
  )
}

export default PanelAddonItem
