import * as React from 'react'
import classnames from 'classnames'

import {useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'

import styles from './HubHeroCard.module.scss'

export interface IHubHeroCardProps {
  header: string
  paragraph: string
  icon: React.ReactNode
  image: string
  backgroundClassName: string
  imageClassName: string
  url: string
}

const HubHeroCard = ({header, paragraph, icon, image, backgroundClassName, imageClassName, url}: IHubHeroCardProps) => {
  const {logEvent} = useAnalyticsQueue()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const onLinkClick = () => {
    window.location.assign(addCountryCodePrefix(url))

    logEvent({
      eventName: 'click to view clinical center page on HCP test catalog hub page',
      eventProperties: {
        'Link destination': url,
      },
    })
  }

  return (
    <button
      className={styles.cardWrapper}
      data-cy="hubHero_card"
      data-testid="cardImageContainer"
      onClick={onLinkClick}
    >
      <div className={classnames(styles.cardImageWrapper, styles[backgroundClassName])} data-testid="cardImage">
        <div className={styles.cardIcon}>{icon}</div>
        <img alt="Card hero" className={classnames(styles.cardImage, styles[imageClassName])} src={image} />
      </div>
      <div className={styles.cardTextWrapper}>
        <h4 className={styles.cardTextHeader}>{header}</h4>
        <p className={styles.cardTextParagraph}>{paragraph}</p>
      </div>
    </button>
  )
}

export default HubHeroCard
