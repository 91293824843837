// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable spellcheck/spell-checker */
import * as React from 'react'
import classNames from 'classnames'
import GeneIcon from 'icons/GeneIcon'
import {PanelCardsItemSelectedContext} from 'wrapper/PanelCardsContext'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {AddToCart} from '@invitae/add-to-cart'
import {NvButton, NvHeading, NvTooltip} from '@invitae/ids-react'
import {ToggleContext, useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'
import {CategoryV2PanelDto} from '@invitae/stargate'

import GeneListTooltip from 'components/GeneListTooltip'
import MainCategoryContext from 'components/MainCategory/MainCategoryContext'
import PanelTagSponsored from 'components/PanelTagTooltip/PanelTagSponsored'
import PanelTagTAT from 'components/PanelTagTooltip/PanelTagTAT'
import {Authenticating} from 'constants/authState'
import {
  ADD_TO_CART_ANALYTICS_EVENT,
  AMYOTROPHIC_SCLEROSIS_C9ORF72_CODE,
  HOVER_OVER_ORDERING_TAG,
  SEE_PANEL_DETAIL_ANALYTICS_EVENT,
} from 'constants/index'
import {PANEL, useGetLinkDetailPage} from 'hooks/useGetLinkToDetailPage'
import useHandlePanelCards, {PanelType} from 'hooks/useHandlePanelCards.hooks'
import getComponentFriendlyPanelDataFromAPIData from 'utils/handleSpecialCases/getComponentFriendlyPanelDataFromAPIData'
import {getIsSponsoredProgramDateDue} from 'utils/utils'

import PanelAddonItem from './PanelAddonItem'
import {getShouldShowFastTurnaroundTag, getTurnaroundTimeDescription} from './panelItemsUtils'

import styles from './PanelItem.module.scss'

interface IPanelItemProps {
  index: number
  panel: CategoryV2PanelDto
  showGeneCount: boolean
  isLastPanel: boolean
}

const NUMBER_OF_BACKGROUND_COLORS = 6
const PANEL_IMAGE = 'https://invitae.imgix.net/web/test-catalog/kit-box-image-panel-card.png?h=200&q=30&auto=format'

const PanelItem = ({index, panel, showGeneCount, isLastPanel}: IPanelItemProps) => {
  const code = panel.code!
  const name = panel.name!
  const totalGenesCountAPIData = panel.totalGenesCount ?? 0
  const metaDescription = panel.metaDescription ?? ''
  const turnaroundTime = panel.turnaroundTime ?? ''
  const geneTests = panel.geneTests ?? []
  const optionalTestsListAPIData = panel.optTests ?? []
  const {cxEnableTestCatalogOrderingTags, cxC9Orf72CatalogPageEnabled} = React.useContext(ToggleContext)
  const {sponsoredTestingData, mainCategory} = React.useContext(MainCategoryContext)
  const {logEvent, linkClickLogEvent} = useAnalyticsQueue()
  const {addonOpenedId, setSelectedAddon} = React.useContext(PanelCardsItemSelectedContext)
  const {authState} = React.useContext(UserAuthStatusContext)
  const {panelDetailLink} = useGetLinkDetailPage(PANEL, code)
  const {removeUrlPrefix} = useCountryLocationPrefix()
  // create primary panel data object from context provided data
  const primaryPanelAPIData = React.useMemo(
    () => ({
      code: code,
      geneTests: geneTests,
      metaDescription: metaDescription,
      name: name,
      totalGenesCount: totalGenesCountAPIData,
      turnaroundTime: turnaroundTime,
    }),
    [geneTests, code, name, totalGenesCountAPIData],
  )

  const {primaryPanelData, optionalTestsData} = getComponentFriendlyPanelDataFromAPIData(
    primaryPanelAPIData,
    optionalTestsListAPIData,
  )
  const {
    //state utils
    isGeneCountEnabled,
    isAddonProvided,
    totalGenesCount,
    sectionHeading,
    showAdded,
    //handle select and add to cart
    handleSelectedPanel,
    handleAddButtonClick,
    resetSelectedItems,
    //main data
    selectedPanels,
    cleanPanelCode,
    setIsHoverOverTags,
  } = useHandlePanelCards({optionalTestsData, primaryPanelData, type: PanelType.CATEGORY})

  const isAddonOpened = addonOpenedId === `${code}`

  const getBackgroundColorNumber = React.useMemo(() => index % NUMBER_OF_BACKGROUND_COLORS, [index])

  const turnaroundTimeDescription = React.useMemo(() => getTurnaroundTimeDescription(turnaroundTime), [turnaroundTime])
  const shouldShowFastTurnaroundTag = React.useMemo(
    () => getShouldShowFastTurnaroundTag(turnaroundTimeDescription) && cxEnableTestCatalogOrderingTags,
    [cxEnableTestCatalogOrderingTags, turnaroundTimeDescription],
  )

  const shouldShowSponsoredTesting = React.useMemo(() => {
    let isProgramDue = false
    if (!sponsoredTestingData) return false
    const panelSponsoredTestingProgram = sponsoredTestingData[code.split('.')[0]]
    if (panelSponsoredTestingProgram) {
      isProgramDue = panelSponsoredTestingProgram.some(item =>
        item.endDate ? getIsSponsoredProgramDateDue(item.endDate) : true,
      )
    }
    return sponsoredTestingData[code.split('.')[0]]?.length > 0 && cxEnableTestCatalogOrderingTags && isProgramDue
  }, [sponsoredTestingData, code, cxEnableTestCatalogOrderingTags])

  const handleRedirectToPanel = React.useCallback(() => {
    logEvent({
      eventName: SEE_PANEL_DETAIL_ANALYTICS_EVENT,
      eventProperties: {
        'Link destination': removeUrlPrefix(panelDetailLink),
        section: sectionHeading,
      },
    })
    window.location.assign(panelDetailLink)
  }, [panelDetailLink, logEvent])

  const trackHoverTooltip = React.useCallback(
    ({duration, tagName}) => {
      setIsHoverOverTags(true)
      logEvent({
        eventName: HOVER_OVER_ORDERING_TAG,
        eventProperties: {
          duration,
          'Panel Name': name,
          'Tag Name': tagName,
        },
      })
    },
    [logEvent, name, setIsHoverOverTags],
  )

  const handleTooltip = React.useCallback(
    ({visible}) => {
      if (!visible) {
        linkClickLogEvent({
          currentPage: 'test catalog main category page',
          eventProperties: {
            'Panel Name': primaryPanelData.title,
            section: sectionHeading,
          },
          linkName: 'view gene list',
        })
      }
    },
    [linkClickLogEvent, sectionHeading, primaryPanelData],
  )

  const handleCloseAddon = React.useCallback(() => {
    //reset selected items and close addon
    setSelectedAddon(``)
    resetSelectedItems()
  }, [setSelectedAddon, resetSelectedItems])

  //additionally handle addon open/closing for the panel card
  const handleAddClick = React.useCallback(() => {
    logEvent({
      eventName: ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'Panel Name': primaryPanelData.title,
        section: sectionHeading,
      },
    })
    if (isAddonProvided && !isAddonOpened) {
      //continue to adding panels
      setSelectedAddon(`${code}`)
    } else {
      handleAddButtonClick()
    }
  }, [
    code,
    primaryPanelData,
    sectionHeading,
    logEvent,
    handleAddButtonClick,
    isAddonOpened,
    setSelectedAddon,
    isAddonProvided,
  ])

  const handleAddToCartButtonText = React.useCallback(() => {
    let addToCartButtonText = 'Add to order'
    if (isAddonOpened) {
      addToCartButtonText = 'Confirm selection'
    }
    if (showAdded) {
      addToCartButtonText = 'Added'
    }
    return addToCartButtonText
  }, [isAddonOpened, showAdded])

  const shouldPanelBeRendered = React.useMemo(() => {
    if (cleanPanelCode === AMYOTROPHIC_SCLEROSIS_C9ORF72_CODE && !cxC9Orf72CatalogPageEnabled) return false

    return true
  }, [cleanPanelCode, cxC9Orf72CatalogPageEnabled])

  const clinicalAreaProperties = React.useMemo(() => {
    // gather the test and add-on
    const productNames = [primaryPanelData.title!]
    optionalTestsData?.forEach(item => {
      if (selectedPanels.includes(item.panelCode!)) {
        if (item.title) productNames.push(item.title)
      }
    })
    const clinicalAreaProperties: {[key: string]: string} = {}
    productNames.forEach(name => {
      clinicalAreaProperties[name] = mainCategory.name!
    })
    return clinicalAreaProperties
  }, [optionalTestsData, primaryPanelData])

  if (!shouldPanelBeRendered) return null

  return (
    <div
      className={classNames(styles.panelItemRoot, {[styles.lastPanelItem]: isLastPanel})}
      data-testid={isAddonProvided ? 'panel-add-on-item' : 'panel-item'}
    >
      <div className={styles.panelMainContainer}>
        <div className={styles.panelTextWrapper}>
          <div className={styles.panelImageTitleWrapper}>
            <div className={classNames(styles.panelImageWrapper, styles[`panelColor${getBackgroundColorNumber}`])}>
              <img alt="Card kit" className={styles.cardImage} src={PANEL_IMAGE} />
            </div>
            <NvHeading className={styles.panelImageTitle} element="h3" level={3}>
              {name}
            </NvHeading>
          </div>
          <div className={styles.panelTextContainer}>
            <NvHeading className={styles.panelRegularTitle} element="h3" level={3}>
              <span>{name}</span>
              {shouldShowSponsoredTesting && (
                <PanelTagSponsored className={styles.sponsoredTag} onChangeVisibleTooltip={trackHoverTooltip} />
              )}
            </NvHeading>
            <p className={styles.panelAdditionalData}>
              Test code: {cleanPanelCode} {turnaroundTimeDescription}{' '}
              {shouldShowFastTurnaroundTag && <PanelTagTAT onChangeVisibleTooltip={trackHoverTooltip} />}
            </p>
            <p className={styles.panelDescription}>{metaDescription}</p>
          </div>
        </div>
        {showGeneCount && (
          <div className={styles.tooltipWrapper}>
            {totalGenesCount > 0 && isGeneCountEnabled(primaryPanelData.title ?? '') && (
              <NvTooltip
                limitedWidth={false}
                onVisibilityChange={handleTooltip}
                overlayContent={
                  <GeneListTooltip
                    optionalTestList={optionalTestsData}
                    primaryPanelData={primaryPanelData}
                    selectedPanels={selectedPanels}
                  />
                }
                showCloseButtonTop
                trigger="click"
              >
                <button
                  className={classNames(styles.cardGeneCount, 'nv-plain-action')}
                  data-testid="panel-item-gene-count-tooltip"
                  type="button"
                >
                  <div className={styles.cardGeneCountInnerContent}>
                    {isAddonProvided ? (
                      <>
                        <GeneIcon className={styles.geneIcon} />
                        Up&nbsp;to&nbsp;{totalGenesCount}
                        <br />
                        genes
                      </>
                    ) : (
                      <>
                        <GeneIcon className={styles.geneIcon} />
                        {totalGenesCount} {totalGenesCount > 1 ? 'genes' : 'gene'}
                      </>
                    )}
                  </div>
                </button>
              </NvTooltip>
            )}
          </div>
        )}
      </div>
      {isAddonProvided && (
        <div
          className={classNames(styles.panelAddonWrapper, {
            [styles.addonOpened]: isAddonOpened,
          })}
        >
          <PanelAddonItem
            geneCount={primaryPanelData.totalGenesCount ?? 0}
            isAddonSelected
            isGeneCountEnabled={isGeneCountEnabled(primaryPanelData.title ?? '')}
            isPrimary
            key="-1"
            title="Primary panel"
          />
          <div className={styles.addonInfoWrapper}>
            <div className={styles.addonInfoIcon}></div>
            <div className={styles.addonInfoData}>
              <p className={styles.addonInfoTitle}>Select add-ons</p>
              <p className={styles.addonInfoDescription}>Add-ons can be included at no additional charge.</p>
            </div>
          </div>
          {optionalTestsData?.map(item => {
            return (
              <PanelAddonItem
                geneCount={item.totalGenesCount ?? 0}
                isAddonSelected={selectedPanels.includes(item.panelCode!)}
                isGeneCountEnabled={isGeneCountEnabled(item.title ?? '')}
                isRequired={item.isRequired}
                key={item.title}
                onSelect={isSelected => handleSelectedPanel(isSelected, item.panelCode!)}
                title={item.title ?? ''}
              />
            )
          })}
        </div>
      )}
      <div className={classNames(styles.panelBottomWrapper)}>
        <div className={styles.panelB}>
          <NvButton
            className={styles.panelDetailButton}
            onClick={handleRedirectToPanel}
            size="medium"
            variant="secondary"
          >
            See test details
          </NvButton>
          <AddToCart
            className={styles.panelAddButton}
            clinicalAreaProperties={clinicalAreaProperties}
            dataTestId="panel-add-btn"
            disabled={showAdded || authState === Authenticating}
            onClick={() => !showAdded && handleAddClick()}
            productId={`${code}`}
            size="medium"
            variant="primary"
          >
            {handleAddToCartButtonText()}
          </AddToCart>
        </div>
        {isAddonOpened && (
          <button
            className={classNames(styles.cancelButton, styles.bottomCancelButton)}
            data-testid="panel-cancel-btn"
            onClick={handleCloseAddon}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  )
}

export default PanelItem
