export enum ReproductiveTestCodes {
  INVITAE_COMPREHENSIVE_CARRIER_SCREEN = 'PR60100',
  INVITAE_GENETIC_HEALTH_SCREEN = 'PR11001',
  INVITAE_SINGLETON_NIPS = 'PR71001',
}

export enum PediatricAndRareDiseasesTestCodes {
  INVITAE_NEURODEVELOPMENTAL_DISORDERS_PANEL = 'PR728434',
  INVITAE_CHROMOSOMAL_MICROARRAY_ANALYSIS = 'PR56033',
  INVITAE_FRAGILE_X_RELATED_DISORDERS_DIAGNOSTIC = 'PR56022',
}
