import * as React from 'react'
import classNames from 'classnames'

import {NvButton} from '@invitae/ids-react'

import {IBreadcrumbItem, useBreadcrumbs} from 'hooks/useBreadcrumbs.hooks'

import IconArrow from './IconArrow'

import styles from './Breadcrumbs.module.scss'

interface IBreadcrumbsProps {
  currentTitle?: string
  isGray?: boolean
}

const Breadcrumbs = ({currentTitle, isGray}: IBreadcrumbsProps) => {
  const breadcrumbs = useBreadcrumbs()

  const isLastBreadcrumbItem = React.useCallback((index: number) => index === breadcrumbs.length - 1, [breadcrumbs])

  if (!breadcrumbs?.length) return null
  return (
    <div
      className={classNames(styles.breadcrumbsWrapper, {
        [styles.breadcrumbsGray]: isGray,
      })}
    >
      <div className={classNames('nv-container', styles.breadcrumbs)}>
        {breadcrumbs.map((item: IBreadcrumbItem, index: number) => (
          <span className={classNames({[styles.activeBreadcrumb]: isLastBreadcrumbItem(index)})} key={item.label}>
            <NvButton disabled={isLastBreadcrumbItem(index)} onClick={item.onClick} type="button" variant="plain">
              {/* if it's last/active breadcrumb and custom title is provided then use it */}
              {/* in other cases use dynamic label */}
              {isLastBreadcrumbItem(index) ? currentTitle ?? item.label : item.label}
            </NvButton>
            {!isLastBreadcrumbItem(index) && <IconArrow className={styles.iconArrow} />}
          </span>
        ))}
      </div>
    </div>
  )
}

export default Breadcrumbs
