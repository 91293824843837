import {useCountryLocationPrefix} from '@invitae/nucleobase'
import {SessionTimeoutConfig} from '@invitae/session-timeout/dist/types'

export const API_REFRESH_TOKEN_URL = `${process.env.STARGATE_HOST}/auth/refresh_token`
export const SIGN_OUT_URL = '/profile/signout'

export function useSessionTimeout() {
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const sessionTimeoutConfig: SessionTimeoutConfig = {
    REFRESH_TOKEN_URL: API_REFRESH_TOKEN_URL,
    SECURITY_LOGOUT_MILLISECONDS: 1800000, // 30 minutes
    SECURITY_TIMEOUT_MILLISECONDS: 900000, // 15 minutes
    SIGNOUT_URL: addCountryCodePrefix(SIGN_OUT_URL),
  }

  return {
    sessionTimeoutConfig,
  }
}
