import * as React from 'react'
import classnames from 'classnames'
import IconCheck from 'icons/IconCheckboxActive'

import {NvButton, NvCheckbox} from '@invitae/ids-react'

import {IOptionalTestData, IPanelData} from 'hooks/types'

import styles from './PanelCardItem.module.scss'

interface IPanelCardsItemAddonProps {
  primaryPanelData: IPanelData
  selectedPanels: string[]
  handleSelectedPanel: (isSelected: boolean, panelCardId: string) => void
  optionalTestList?: IOptionalTestData[]
  handleMultiplePanelAdd: () => void
  buttonDisabled: boolean
  buttonText: string
}

const PanelCardsItemAddon = ({
  selectedPanels,
  optionalTestList,
  handleMultiplePanelAdd,
  handleSelectedPanel,
  buttonDisabled,
  buttonText,
}: IPanelCardsItemAddonProps) => {
  const isAddonSelected = (panelCode: string) => selectedPanels.includes(panelCode)

  return (
    <div className={styles.cardCheckboxList} data-testid="panel-cards-addon" id="panelCardsAddonId">
      <span className={classnames(styles.checkWrapper, styles.firstItem)} data-testid="panel-cards-primary-panel">
        <IconCheck />
        Primary panel
      </span>
      {optionalTestList?.map(item =>
        item.isRequired ? (
          <span className={styles.checkWrapper} data-testid="panel-cards-required-panel" key={item.title}>
            <IconCheck />
            {item.title}
          </span>
        ) : (
          <>
            <NvCheckbox
              checked={isAddonSelected(item.panelCode!)}
              className={classnames(styles.title, styles.panelAddonStyles)}
              data-testid="panel-add-on-item"
              id={item.panelCode}
              key={item.title}
              label={item.title ?? ''}
              onChange={() => handleSelectedPanel(!isAddonSelected(item.panelCode!), item.panelCode!)}
            />
          </>
        ),
      )}
      <NvButton
        className={styles.cardConfirmation}
        disabled={buttonDisabled}
        onClick={handleMultiplePanelAdd}
        size="medium"
        variant="secondary"
      >
        {buttonText}
      </NvButton>
    </div>
  )
}

export default PanelCardsItemAddon
