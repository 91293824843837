export const TOGGLE_PROJECT_NAME = 'customer-experience'
export const FEATURE_FLAG_APP_NAME = 'test-catalog-frontend'

export const CX_CANCER_PAYOR_ISSUES_ENABLED = 'cx_cancer_payor_issues_enabled'
export const CX_CUSTOM_RNA_HCX = 'cx_custom_rna_hcx'
// eslint-disable-next-line spellcheck/spell-checker
export const CX_PEDIATRIC_CMA_IN_SF = 'cx_pediatric_cma_in_sf'

export const IS_CANCER_PAYOR_ISSUES_ENABLED = 'isCancerPayorIssuesEnabled'
export const CX_RETAIN_CUSTOM_PANEL_CONTEXT = 'cx-retain-custom-panel-context'
export const CX_ORDERING_CARRIER_NIPS_EXCEPTION = 'cx_ordering_carrier_nips_exception'
export const CX_IS_STARGATE_CART_SVC_PROXY_ENABLED = 'cx_is_stargate_cart_svc_proxy_enabled'

export const FEATURE_FLAGS: string[] = [
  'cx_enable_test_catalog_ordering_tags', // https://invitae.jira.com/browse/WT-26113
  'cx_pgx_hcx_online_ordering', // https://invitae.jira.com/browse/CEPN-95
  CX_CANCER_PAYOR_ISSUES_ENABLED, // https://invitae.jira.com/browse/CORR-54
  CX_CUSTOM_RNA_HCX, // https://invitae.jira.com/browse/OFSW-60
  CX_PEDIATRIC_CMA_IN_SF,
  CX_RETAIN_CUSTOM_PANEL_CONTEXT, // https://invitae.jira.com/browse/CELT-1736
  CX_ORDERING_CARRIER_NIPS_EXCEPTION, // https://invitae.jira.com/browse/CELT-1914
  CX_IS_STARGATE_CART_SVC_PROXY_ENABLED, // https://invitae.jira.com/browse/CELT-1918
]
