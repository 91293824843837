import * as React from 'react'
import classnames from 'classnames'

import {IPanelData, IPanelGene} from 'hooks/types'

import styles from './GeneListTooltip.module.scss'

interface IGeneListTooltipItemProps {
  isPanelSelected: (panelId: string) => boolean
  isGeneSelected?: (gene: IPanelGene) => boolean
  item: IPanelData
  title?: string
}

const GeneListTooltipItem = ({isPanelSelected, item, title, isGeneSelected}: IGeneListTooltipItemProps) => {
  const alphabeticalFilteredGenes = item?.geneList?.sort(function (previousGene, nextGene) {
    return previousGene.name.localeCompare(nextGene.name)
  })

  const getTotalGenesCount = item.totalGenesCount ?? 0

  if (!item.totalGenesCount) return null
  return (
    <div
      className={classnames(styles.geneSection, {
        [styles.unselectedPanel]: !isPanelSelected(item.panelCode!),
      })}
      data-testid="gene-list-tooltip-item"
      key={item.panelCode}
    >
      <p className={styles.tooltipGenesTitle}>
        {title || item.title} ({getTotalGenesCount} {getTotalGenesCount > 1 ? 'genes' : 'gene'})
      </p>
      <span className={styles.tooltipGenesDescription}>
        {alphabeticalFilteredGenes?.map((gene, index) => (
          <p
            className={classnames({
              [styles.unselectedGene]: isGeneSelected && !isGeneSelected(gene),
            })}
            key={gene.name}
          >
            {gene.name}
            {index < alphabeticalFilteredGenes.length - 1 ? ', ' : ''}
          </p>
        ))}
      </span>
    </div>
  )
}

export default GeneListTooltipItem
