import {
  CategoryGenesDto,
  CategoryPageDto,
  CommercialTestDto,
  Configuration,
  GenePageDto,
  GeneV2Dto,
  TestCatalogApi,
  TestPanelCardDto,
  TestPanelDetailedPageDto,
} from '@invitae/stargate'

const makeField = (name: string, value: any, componentName: string, engine: string) => ({
  fields: {
    [name]: value,
  },
  sys: {
    componentName,
    engine,
    id: typeof value === 'string' ? value : JSON.stringify(value),
  },
})

class TestCatalogAPI {
  client: TestCatalogApi

  constructor() {
    const config = new Configuration({basePath: process.env.STARGATE_HOST})
    this.client = new TestCatalogApi(config)
  }

  async getMainCategory(code: string): Promise<CategoryPageDto> {
    return (await this.client.aggregateMainCategoryPageV2FromTestCatalogController(code)).data
  }

  serializePanelResponse(panel: CommercialTestDto) {
    const {code, optTests, name, geneTests} = panel
    if (!code || !optTests || !name || !geneTests)
      throw new Error('aggregateTestPanelPageFromTestCatalogController serialization error')
    return {
      ...panel,
      code: code || '',
      geneTests: geneTests || [],
      name: name || '',
      optTests: optTests || [],
    }
  }

  async getPanel(panelCode: string) {
    const panelPage = (await this.client.aggregateTestPanelPageFromTestCatalogController(panelCode)).data
    const panel = this.serializePanelResponse(panelPage.panel)
    const engine = 'testCatalog'
    const result = {
      fields: {
        ...panel,
        categories: panel?.categories?.map(category => ({
          fields: category,
          sys: {
            componentName: 'SubCategory',
            engine,
            id: category.code,
          },
        })),
        clinicalDescription: panel.clinicalDescription,
        description: panel.description,
        geneTests: makeField(
          'geneTests',
          panel.geneTests?.map(geneTest => makeField('geneTest', geneTest, 'GeneTest', engine)),
          'PrimaryPanel',
          engine,
        ),
        name: panel.name,
      },
      sys: {
        componentName: 'Panel',
        engine,
        id: panel.code,
      },
    }
    return result
  }

  async getPanelCard(panelCode: string): Promise<TestPanelCardDto> {
    return (await this.client.aggregateTestPanelCardFromTestCatalogController(`PR${panelCode}`)).data
  }

  async getPanelDetailedPage(panelCode: string): Promise<TestPanelDetailedPageDto> {
    return (await this.client.aggregateTestPanelDetailedPageFromTestCatalogController(`PR${panelCode}`)).data
  }

  async getGeneByPRCode(geneCode: string): Promise<GenePageDto> {
    return (await this.client.aggregateGenePageFromTestCatalogController(`PR${geneCode}`)).data
  }

  async getGeneByPRCodeV2(geneCode: string): Promise<GeneV2Dto[]> {
    return (await this.client.aggregateGenePageV2FromTestCatalogController(`PR${geneCode}`)).data
  }

  async getGenesByCategoryCode(
    categoryCode: string,
    searchTerm = '',
    from = 0,
    size = 20,
  ): Promise<CategoryGenesDto[]> {
    return (await this.client.aggregateMainCategoryGenesFromTestCatalogController(categoryCode, from, size, searchTerm))
      .data
  }
}

export default TestCatalogAPI
