import {useRouter} from 'next/router'

import {useCountryLocationPrefix} from '@invitae/nucleobase'

import {humanize, removeBasePath} from 'utils/utils'

export interface IBreadcrumbItem {
  label: string
  pathSection: string
  onClick: () => void
}

export function useBreadcrumbs() {
  const router = useRouter()
  const {pathCountryCode} = useCountryLocationPrefix()
  const pathname = `/${removeBasePath(router.asPath)}`

  const splitPathname =
    pathname
      ?.split('?')[0]
      // Split pathname into sections.
      ?.split('/') ?? []

  // include a "Test Catalog" breadcrumb by default.
  const breadcrumbs: IBreadcrumbItem[] = [
    {
      label: 'Test catalog',
      onClick: () => {
        router.push(`/${pathCountryCode.toLowerCase()}/providers/test-catalog`)
      },
      pathSection: `/${pathCountryCode.toLowerCase()}/providers/test-catalog`,
    },
  ]

  splitPathname
    // Ignore trailing slash or double slashes in the URL
    .filter(
      (pathSection: string, index: number) =>
        (pathSection !== '' && index !== 0) || (pathSection === '/' && index === 0),
    )
    .reduce((previousSection: string, currentSection: string) => {
      // Combine the last route section with the currentSection.
      // For example, `pathname = /1/2/3` results in match checks for
      // `/1`, `/1/2`, `/1/2/3`.
      const pathSection = !currentSection ? '/' : `${previousSection}/${currentSection}`

      const breadcrumb = {
        label: humanize(currentSection),
        onClick: () => {
          router.push(pathSection)
        },
        pathSection,
      }

      breadcrumbs.push(breadcrumb)

      return pathSection === '/' ? '' : pathSection
    }, '')

  return breadcrumbs.length > 1 ? breadcrumbs : []
}
