export const CARRIER_PANEL_CODE = '60100' // /physician/tests/60100/
export const CARRIER_PANEL_CODE_PRIMARY_GENE_1 = 'CFTR'
export const CARRIER_PANEL_CODE_PRIMARY_GENE_2 = 'SMN1'

export const CARRIER_PANEL_CODE_PRIMARY_NAME_DEFAULT_GENE_1 = 'PR62052.08'
export const CARRIER_PANEL_CODE_PRIMARY_NAME_DEFAULT_GENE_2 = 'PR62275.08'

export const CARRIER_PANEL_OPTIONAL_TEST_TITLE = 'Add-on Comprehensive Carrier Screen genes'

export const carrierCategoryPanelCodes: {[code: string]: string} = {
  //Invitae Comprehensive Carrier Screen
  '60100': 'Add-on Comprehensive Carrier Screen genes',

  //Invitae Broad Carrier Screen
  '60101': 'Add-on Broad Carrier Screen genes',

  //Invitae Core Carrier Screen
  '60102': 'Add-on Core Carrier Screen genes',

  //Invitae Comprehensive Carrier Screen without X-linked Disorders
  '60200': 'Add-on Comprehensive Carrier Screen without X-linked Disorders genes',

  //Invitae Broad Carrier Screen without X-linked Disorders
  '60201': 'Add-on Broad Carrier Screen without X-linked Disorders genes',
}

export const NIPS_CORE_SCA_SEX_ADDONS_REGEX = /^PR71001\.(\d{2})\.[135]$/g
export const NIPS_CORE_EXPANDED_SCA_SEX_ADDONS_REGEX = /^PR71003\.(\d{2})\.[123]$/g

export const NIPS_FETAL_SEX_REPORT_PARTIAL_TITLE = 'Add-on Sex Chromosome'
export const NIPS_FETAL_SEX_WILL_REPORT_PARTIAL_TITLE = 'WILL'
export const ADD_ON_SEX_CHR_ANEUPLOIDIES_WILL_PREDICT_FETAL_SEX_REGEX = /^PR7100[1345]\.(\d{2})\.1$/g

export const NIPS_FETAL_SEX_WILL_REPORT_ADDON_REGEX = /(PR71001).([0-9]+).1/g
export const NIPS_FETAL_SEX_WILL_NOT_REPORT_ADDON_REGEX = /PR71001.([0-9]+).3/g
export const NIPS_ANY_CODE_REGEX = /^PR7100[0-9]\./

export const CARRIER_COMPREHENSIVE_PANEL_TITLE = 'Invitae Comprehensive Carrier Screen'

export const disabledGeneCountPanelList = [
  //Pregnancy loss
  '81110',
  '81120',
  //NIPS,
  '71001',
  '71002',
  '71003',
  '71004',
  '71005',
  '71006',
]

export const shouldDisplayZeroGenePanel = (prCode: string): boolean => prCode.match(NIPS_ANY_CODE_REGEX) !== null

export const NIPS_SINGLETON_MICRODELETION_SYNDROMES_ADDON = 'Add-on Microdeletion Syndromes'

export const PGX_PANEL_CODE = '744239'

export const requiredPanelAddonsCodes = [
  'PR144140', //Invitae Common Hereditary Cancers + RNA Panel -> Common Hereditary Cancers Genes Eligible for RNA Analysis
  'PR246508', //Invitae Multi-Cancer + RNA Panel -> Multi-Cancer Genes Eligible for RNA Analysis (0 genes)
]

export const FAMILLIAL_FOLLOW_UP_CODE = 'DG612248'
export const FAMILLIAL_FOLLOW_UP_PREGNANCY_CODE = 'DG394691'
export const FIBROBLAST_CELL_CULTURE_CODE = 'DG092158'
export const REPRODUCTIVE_KARYOTYPE_CODE = 'DG303169'
export const AMYOTROPHIC_SCLEROSIS_C9ORF72_CODE = '444002'

export const PRODUCT_TYPE_RNA = 'Diagnostic+RNA'
export const PRODUCT_TYPE_NIPS = 'NIPS'

export const PRODUCT_TYPE_PREVENTIVE = 'Preventive'

export const REQUIRED_PRODUCT_TYPES = [
  'STAT-TAT',
  PRODUCT_TYPE_PREVENTIVE,
  'Exome v3',
  PRODUCT_TYPE_RNA,
  'Exome Preventive Addon',
  'SMA-STAT-TAT',
]

export const HBA1_HBA2_GENE = 'HBA1, HBA2'
export const SMN1_SMN2_GENE = 'SMN1, SMN2'

export const LONG_GENES = [HBA1_HBA2_GENE, SMN1_SMN2_GENE]

export const shouldShowNameProductTypeList = [
  'NIPS',
  'Developmental Disorders (Cytogenetics)',
  'Fragile X Syndrome Sendout',
  'Pregnancy Loss (Cytogenetics)',
]

// all variants of panel page url: old and new
export const TEST_REGEX = /test-([0-9])+(\/|$)/
export const OLD_PANEL_REGEX = /tests\/\d+/
export const GENE_REGEX = /gene-([0-9])+(\/|$)/

// complete URL with test/gene tests
export const URL_TEST_REGEX = new RegExp('^providers/test-catalog/test-([0-9])+(/|$)$')
export const URL_OLD_PANEL_REGEX = new RegExp('^providers/test-catalog/tests/\\d+$')
export const URL_GENE_REGEX = new RegExp('^providers/test-catalog/gene-([0-9])+(/|$)$')

// eslint-disable-next-line spellcheck/spell-checker
export const PANEL_CUSTOMISATION_STATUS_ITEM = 'panelCustomisationStatus'

export const EXTERNAL_LINK_CLASS = 'class="external"'

export const PRODUCT_TYPES_MIX = 'FORBIDDEN_PRODUCT_TYPES_MIX'

export const CUSTOM_ASSAY_INFO_TEST = 'PR444006'
export const CUSTOM_ASSAY_INFO_GENE = 'PR330976'

//temporary disable sponsored testing section on Invitae Arrhythmia and Cardiomyopathy Comprehensive Panel
//https://invitae.jira.com/browse/CEPN-277
export const DISABLED_SPONSORED_SECTION_CLEAN_CODE = '02101'
