import * as React from 'react'
import classnames from 'classnames'

import {useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'

import styles from './IconCardItem.module.scss'

export interface IconCardItemProps {
  heading: React.ReactNode
  icon: React.ReactNode
  url: string
  sectionName?: string
}

export const clickToCategoryPageAnalytics = (sectionName: string) => `main category page on '${sectionName}' section`

const IconCardSectionItem = ({heading, icon, url, sectionName}: IconCardItemProps) => {
  const {linkClickLogEvent} = useAnalyticsQueue()
  const {addCountryCodePrefix} = useCountryLocationPrefix()

  const handleClickOnCategory = () => {
    linkClickLogEvent({
      currentPage: 'reproductive clinical center page',
      eventProperties: {
        'Main Category': heading,
      },
      linkName: clickToCategoryPageAnalytics(sectionName || 'unspecified'),
    })
    return true
  }

  return (
    <a
      className={classnames(styles.cardWrapper, styles.miniCard)}
      data-testid="icon-cards-item"
      href={addCountryCodePrefix(url)}
      onClick={handleClickOnCategory}
      role="button"
    >
      <div className={styles.imageWrapper}>{icon}</div>
      <div className={classnames(styles.card)}>
        <div className={classnames(styles.cardHeaderAndText)}>
          <h3 className={classnames(styles.heading, 'nv-mh4')}>{heading}</h3>
        </div>
      </div>
    </a>
  )
}
export default IconCardSectionItem
