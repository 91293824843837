import * as React from 'react'
import {PanelCardsItemIndexContext} from 'wrapper/PanelCardsContext'

import {IPanelCardsSectionFields} from '@invitae/contentful-types'
import {NucleobaseComponent} from '@invitae/nucleobase'

import PanelCardsSection from './index'
import PanelCardsItemWrapper from './PanelCardsItem/PanelCardsItemWrapper'

const ContentfulPanelCardsSection: NucleobaseComponent<IPanelCardsSectionFields> = ({fields, id}) => {
  const items = React.useMemo(
    () =>
      fields.items.map((item, index) => (
        <PanelCardsItemIndexContext.Provider key={`PanelCardItem_${id}_${index}`} value={index + 1}>
          <PanelCardsItemWrapper fields={item.fields} id={item.sys.id} key={item.sys.id} />
        </PanelCardsItemIndexContext.Provider>
      )),
    [fields.items],
  )

  return <PanelCardsSection id={id} {...fields} items={items} />
}

export default ContentfulPanelCardsSection
