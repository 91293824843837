import {Configuration, FeatureFlagsApi, FeatureFlagsResponseDto} from '@invitae/stargate'

import {FEATURE_FLAG_APP_NAME} from 'constants/featureFlags'
import {TOGGLE_PROJECT_NAME} from 'constants/featureFlags'
import {TUser} from 'hooks/useUserAuthenticationStatus.hooks'

class FeatureFlagsAPI {
  client: FeatureFlagsApi

  constructor() {
    const config = new Configuration({basePath: process.env.STARGATE_HOST})
    this.client = new FeatureFlagsApi(config)
  }

  async getFeatureFlags(flags: Array<string>, user: TUser | null): Promise<FeatureFlagsResponseDto> {
    const featureFlagsRequest = {
      appName: FEATURE_FLAG_APP_NAME,
      flagNames: flags,
      projectName: TOGGLE_PROJECT_NAME,
      ...(user && {
        context: {
          properties: {
            country: user.country,
            organizations: user.organization,
          },
          userId: user.email,
        },
      }),
    }
    const featureFlags = await this.client.getFeatureFlags(featureFlagsRequest)
    return featureFlags.data
  }
}

export default FeatureFlagsAPI
