import * as React from 'react'

import {NvTooltip} from '@invitae/ids-react'

import IconClock from './IconClock'

import styles from './PanelTagTAT.module.scss'

const tooltipText = 'This test has a faster turnaround time than most Invitae panels.'
const fastTurnaround = 'Fast turnaround'

const PanelTagTAT = ({
  onChangeVisibleTooltip,
}: {
  onChangeVisibleTooltip: ({duration, tagName}: {duration: number | null; tagName: string}) => void
}) => {
  return (
    <NvTooltip
      onVisibilityChange={({duration}) => onChangeVisibleTooltip({duration, tagName: fastTurnaround})}
      position="bottom"
      text={tooltipText}
    >
      <span className={styles.tatTag}>
        <IconClock className={styles.tatIcon} />
        {fastTurnaround}
      </span>
    </NvTooltip>
  )
}

export default PanelTagTAT
