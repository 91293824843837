import * as React from 'react'
import classNames from 'classnames'

import IconClear from './IconClear'

import styles from './SearchInput.module.scss'

export interface ISearchInput {
  onChange: (text: string) => void
  onClear?: () => void
  className?: string
  clearable?: boolean
  fullWidth?: boolean
  initialValue?: string
  rounded?: boolean
  placeholder?: string
}

const SearchInput: React.FC<ISearchInput> = ({
  onChange,
  onClear,
  clearable,
  fullWidth,
  className,
  rounded,
  initialValue = '',
  placeholder = 'Search',
}) => {
  const [text, setText] = React.useState(initialValue)

  const handleOnClearClick = React.useCallback(() => {
    setText('')
    onClear && onClear()
  }, [onClear, setText])

  const handleChange = (value: string) => {
    setText(value)
    onChange(value)
  }

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <input
        className={classNames(styles.input, {
          [styles.rounded]: rounded,
        })}
        data-testid="search-input"
        onChange={e => handleChange(e.target.value)}
        placeholder={placeholder}
        value={text}
      />
      {clearable && text.length > 0 && (
        <button className={styles.iconClearWrapper} data-testid="clear-search-btn" onClick={handleOnClearClick}>
          <IconClear />
        </button>
      )}
    </div>
  )
}

export default SearchInput
