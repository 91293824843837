import {OptTestDto} from '@invitae/stargate'

import {SelectedTest} from '../ReproductiveBaseComponentBlock'

interface IGetResetExclusiveTests {
  test: OptTestDto
  newValue: boolean
  exclusiveTests?: RegExp[]
  selectedTests: SelectedTest
}
export const getResetExclusiveTests = ({
  test,
  newValue,
  exclusiveTests = [],
  selectedTests,
}: IGetResetExclusiveTests) => {
  const changeExclusiveTests: {[x: string]: boolean} = {}
  const matchedExclusiveTest = exclusiveTests.some(regex => test.code.match(regex))
  if (newValue && matchedExclusiveTest) {
    const findSelectedUniqueItem = Object.keys(selectedTests).find(
      code => exclusiveTests.some(regex => code.match(regex)) && selectedTests[code],
    )
    if (findSelectedUniqueItem) {
      changeExclusiveTests[findSelectedUniqueItem] = false
    }
  }
  return changeExclusiveTests
}
