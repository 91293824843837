export const redirectToSignIn = () => {
  const redirectUrl = `${process.env.SIGN_IN_PAGE}?next=${encodeURIComponent(
    `${window.location.pathname}${window.location.search}`,
  )}`
  window.location.assign(redirectUrl)
}

export const pgxOrderPage = `https://view.publitas.com/invitae/trf899_pgx_order_form/page/1`

export const redirectToPGxPage = () => {
  const redirectUrl = pgxOrderPage
  window.location.assign(redirectUrl)
}
