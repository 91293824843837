import * as React from 'react'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {CartContext} from '@invitae/physician-cart'

import {redirectToSignIn} from 'components/PanelCardsSection/PanelCardsItem/utils'
import {Authenticated, Unauthenticated} from 'constants/authState'
import {PRODUCT_TYPES_MIX} from 'constants/specialCases'

export interface IHandleGeneSelectProps {
  geneCode: string
  logAddToCartEvent?: () => void
}

export interface IHandleGeneSelectResult {
  showAdded: boolean
  handleAddToCart: (onAddToCartSuccess?: () => void) => Promise<boolean>
}

function useHandleGeneSelection({geneCode, logAddToCartEvent}: IHandleGeneSelectProps): IHandleGeneSelectResult {
  const [showAdded, setShowAdded] = React.useState(false)
  const {addToCart} = React.useContext(CartContext)
  const {authState} = React.useContext(UserAuthStatusContext)

  const handleAddToCart = React.useCallback(
    async (onAddToCartSuccess?: () => void) => {
      logAddToCartEvent && logAddToCartEvent()

      if (authState === Authenticated) {
        const cartResponse = await addToCart([geneCode], undefined, [{codes: [geneCode], parentId: '', parentType: ''}])
        /* TODO: Handle cart validation issue: https://invitae.jira.com/browse/CFTC-147 */
        if (cartResponse?.errors && cartResponse.errors['validationCode'] !== PRODUCT_TYPES_MIX) {
          return false
        }
        setShowAdded(true)
        onAddToCartSuccess && onAddToCartSuccess()
        return true
      } else if (authState === Unauthenticated) {
        redirectToSignIn()
      }

      return false
    },
    [geneCode, addToCart, authState],
  )
  return {
    handleAddToCart,
    showAdded,
  }
}

export default useHandleGeneSelection
