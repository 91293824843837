import * as React from 'react'
import classNames from 'classnames'
import {ReproductiveTestCodes} from 'common/testCodes'
import Link from 'next/link'

import {NvCheckbox, NvTooltip} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'
import {OptTestDto} from '@invitae/stargate'

import GeneListTooltip from 'components/GeneListTooltip'
import {PANEL, useGetLinkDetailPage} from 'hooks/useGetLinkToDetailPage'
import {checkIfGeneNameSpecialCase} from 'utils/utils'

import {ComponentBlockStatus, ReproductiveBasePanel, SelectedTest} from '../ReproductiveBaseComponentBlock'
import {getResetExclusiveTests} from './utils'

import styles from '../ReproductiveBaseComponentBlock.module.scss'

const PANEL_TO_HIDE_GENES_ON = ReproductiveTestCodes.INVITAE_SINGLETON_NIPS
const CLICK_ON_PANEL_PAGE_ON_PREGNANT =
  "click to panel page on 'if your patient is pregnant' section of reproductive clinical center page"
export const CLICK_ON_PANEL_PAGE_ON_CONCEIVE =
  "click to panel page on 'trying to conceive' section of reproductive clinical center page"
const shouldShowGenes = (panelCode: string) => !panelCode.includes(PANEL_TO_HIDE_GENES_ON)

export interface IReproductiveBasePanelItemProps {
  panel: ReproductiveBasePanel

  requiredPanels: string[]
  exclusiveTests?: RegExp[]

  packageType: string
  isLoading: boolean

  selectedTests: SelectedTest
  setSelectedTests: React.Dispatch<React.SetStateAction<SelectedTest>>
  status: ComponentBlockStatus

  panels: ReproductiveBasePanel[]
  selectedCodes: string[]
}

const ReproductiveBasePanelItem = ({
  panel,
  packageType,
  isLoading,
  setSelectedTests,
  requiredPanels,
  exclusiveTests = [],
  selectedTests,
  panels,
  selectedCodes,
  status,
}: IReproductiveBasePanelItemProps) => {
  const {logEvent} = useAnalyticsQueue()
  const {panelDetailLink} = useGetLinkDetailPage(PANEL, panel.fields.code)

  const handleClickOnSection = React.useCallback(
    (panelName: string) => {
      logEvent({
        eventName:
          packageType === 'early pregnancy' ? CLICK_ON_PANEL_PAGE_ON_PREGNANT : CLICK_ON_PANEL_PAGE_ON_CONCEIVE,
        eventProperties: {
          'Panel Name': panelName,
        },
      })
    },
    [packageType],
  )

  const getTotalGenesCount = () => {
    let totalGenesCount = panel.fields.totalGenesCount || 0
    if (panel.fields.optTests[0]?.geneTests.find(element => checkIfGeneNameSpecialCase(element.name))) {
      totalGenesCount = totalGenesCount + 1
    }
    return totalGenesCount
  }
  const getOptionalTestGenesCount = (test: OptTestDto) => {
    let genesCount = test.totalGenesCount || 0
    if (test.geneTests.find(element => checkIfGeneNameSpecialCase(element.name))) {
      genesCount = genesCount + 1
    }
    return genesCount
  }

  const onChangeItem = React.useCallback(
    (test: OptTestDto) => (newValue: boolean) => {
      if (!requiredPanels.includes(test.code)) {
        const changeExclusiveTests: {[x: string]: boolean} = getResetExclusiveTests({
          exclusiveTests,
          newValue,
          selectedTests,
          test,
        })
        setSelectedTests(currentTests => ({
          ...currentTests,
          [test.code]: newValue,
          ...changeExclusiveTests,
        }))
      }
    },
    [selectedTests, requiredPanels],
  )

  const getGenesName = ({code, test}: {code: ReproductiveBasePanel['fields']['code']; test: OptTestDto}) => {
    const geneCount = code.includes(ReproductiveTestCodes.INVITAE_SINGLETON_NIPS)
      ? null
      : getOptionalTestGenesCount(test)

    const genesText = geneCount ? `(${geneCount} genes)` : ''

    return `${test.name} ${genesText}`
  }

  return (
    <React.Fragment key={panel.fields.name}>
      <div className={styles.panelNameContainer}>
        <span
          aria-hidden="true"
          data-testid="reproductive-gene-title"
          onClick={() => handleClickOnSection(panel.fields.name)}
          role="button"
        >
          <Link href={panelDetailLink}>{panel.fields.name}</Link>
        </span>
        {shouldShowGenes(panel.fields.code) && (
          <NvTooltip
            limitedWidth={false}
            overlayContent={
              <GeneListTooltip
                optionalTestList={panel.fields.optTests.map((test: OptTestDto) => ({
                  geneList: test.geneTests.map(el => ({code: el.code, name: el.name})),
                  panelCode: test.code,
                  title: test.name,
                  totalGenesCount: test.totalGenesCount || 0,
                }))}
                primaryPanelData={{
                  geneList: panel.fields.geneTests.fields.geneTests.map((test: {[x: string]: any}) => ({
                    code: test.fields.geneTest.code,
                    name: test.fields.geneTest.name,
                  })),
                  panelCode: panel.fields.code,
                  title: panel.fields.name || '',
                  totalGenesCount: panel.fields.totalGenesCount || 0,
                }}
                selectedPanels={panels
                  .map(mainPanel => mainPanel.fields.code)
                  .concat(selectedCodes)
                  .concat(requiredPanels)}
              />
            }
            showCloseButtonTop
            trigger="click"
          >
            <button
              className={classNames('nv-plain-action', styles.geneCount)}
              data-testid="reproductive-gene-count"
              type="button"
            >
              {!isLoading && panel.fields.optTests.length ? 'up to ' : ''}
              {isLoading ? '—' : getTotalGenesCount()} genes
            </button>
          </NvTooltip>
        )}
      </div>
      {status === ComponentBlockStatus.SELECTING_PANELS && (
        <div className={styles.optionalTestContainer}>
          <div className={styles.isolatedCheckbox}>
            <NvCheckbox
              checked
              disabled
              id={ComponentBlockStatus.SELECTING_PANELS}
              label="Primary panel"
              size="small"
            />
          </div>
          {panel.fields.optTests.map((test: OptTestDto) => (
            <div key={test.code}>
              <NvCheckbox
                checked={!!selectedTests[test.code] || requiredPanels.includes(test.code)}
                disabled={requiredPanels.includes(test.code)}
                id={test.code}
                label={getGenesName({code: panel.fields.code, test})}
                onChange={onChangeItem(test)}
                size="small"
              />
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  )
}

export default ReproductiveBasePanelItem
