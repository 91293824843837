import * as React from 'react'
import {noop} from 'lodash'

import {CartContextWrapper} from '@invitae/physician-cart'

import {TUser} from 'hooks/useUserAuthenticationStatus.hooks'

import {UserAuthStatusContext} from './UserAuthStatusContext'

declare global {
  interface WindowUser {
    email: string
    isAuthenticated: boolean
  }
  interface Window {
    // To access basic user object in physician-cart for authentication check purposes
    user: WindowUser
  }
}

export const setCartAuthentication = ({email}: TUser) => {
  window.user = {email, isAuthenticated: true}
}

export const gotoCartPage = () => {
  window.location.assign(`${process.env.CART_PAGE}`)
}

const CartContextAuthWrapper = ({children, featureFlags}: any) => {
  const {authState} = React.useContext(UserAuthStatusContext)

  if (!authState.isAuthenticated) return children
  //load CartContextWrapper only if the user is authenticated
  return (
    <CartContextWrapper
      apiParameters={{basePath: `${process.env.CART_BASE_PATH}`}}
      featureFlags={featureFlags}
      goToSignInPage={noop}
      gotoCartPage={gotoCartPage}
    >
      {children}
    </CartContextWrapper>
  )
}

export default CartContextAuthWrapper
