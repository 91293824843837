import * as React from 'react'
import {uniqBy} from 'lodash'
import deepcopy from 'ts-deepcopy'
import {PanelCardsItemSelectedContext} from 'wrapper/PanelCardsContext'

import {NvSpinner} from '@invitae/ids-react'
import {CategoryV2PanelDto} from '@invitae/stargate'

import MainCategoryContext from 'components/MainCategory/MainCategoryContext'
import {useGetFilteredSubcategories} from 'hooks/useGetFilteredSubcategories'
import {getCleanCode} from 'utils/utils'
import {sortFullPanelListByListFromContentful} from 'utils/utils'

import CategorySideMenuWrapper, {CategorySideMenuContext} from '../CategorySideMenu/CategorySideMenuWrapper'
import PanelItem from './PanelItem'

interface IPanelTabProps {
  frequentlyOrderedPanels?: string[]
}

const frequentlyOrderedTestsMenuItemId = 0
const frequentlyOrderedTestsMenuItemTitle = 'Frequently ordered tests'

const frequentlyOrderedTestsMenuItem = {
  id: frequentlyOrderedTestsMenuItemId,
  title: frequentlyOrderedTestsMenuItemTitle,
}

export const allPanelsMenuItemId = -1
export const allPanelsMenuItemTitle = 'All Tests'

const allPanelsMenuItem = {
  id: allPanelsMenuItemId,
  title: allPanelsMenuItemTitle,
}

const PanelTab = ({frequentlyOrderedPanels}: IPanelTabProps) => {
  const {panels} = React.useContext(MainCategoryContext).mainCategory
  const {filteredSubCategories} = useGetFilteredSubcategories() ?? []

  const frequentlyOrderedList =
    React.useMemo(() => {
      if (!frequentlyOrderedPanels || frequentlyOrderedPanels.length <= 0) return []
      let getPanelList = panels?.filter(item => frequentlyOrderedPanels?.includes(getCleanCode(item.code!))) ?? []
      getPanelList = sortFullPanelListByListFromContentful(getPanelList, frequentlyOrderedPanels)
      return getPanelList
    }, [panels, frequentlyOrderedPanels]) ?? []

  const isFrequentlyOrderedListProvided = React.useMemo(() => frequentlyOrderedList.length > 0, [])

  const [tooltipOpenedId, setTooltipOpenedId] = React.useState('')
  const [addonOpenedId, setAddonOpenedId] = React.useState('')
  const setSelectedTooltip = React.useCallback((tooltipId: string) => {
    setTooltipOpenedId(tooltipId)
  }, [])

  const setSelectedAddon = React.useCallback((cardId: string) => {
    setAddonOpenedId(cardId)
  }, [])

  const [activeMenuItemId, setActiveMenuItemId] = React.useState<number>()

  const panelMenuList = React.useMemo(() => {
    const rawMenuList = [
      ...deepcopy(filteredSubCategories ?? []).map((item, index) => ({id: index + 1, title: item.name!})),
    ]
    if (isFrequentlyOrderedListProvided) {
      rawMenuList.unshift(frequentlyOrderedTestsMenuItem)
    }
    //add the 'all panels' list at the start even if the frequently ordered list was appended
    rawMenuList.unshift(allPanelsMenuItem)
    return rawMenuList
  }, [frequentlyOrderedTestsMenuItem, filteredSubCategories, isFrequentlyOrderedListProvided])

  const prependFrequentlyOrderedList = React.useCallback(
    (allPanels: CategoryV2PanelDto[]) => {
      const filteredList = allPanels.filter(item => !frequentlyOrderedPanels?.includes(getCleanCode(item.code!)))
      filteredList?.unshift(...frequentlyOrderedList)
      return filteredList
    },
    [frequentlyOrderedPanels],
  )

  const isSubcategoryActiveItemId = React.useMemo(
    () => activeMenuItemId !== frequentlyOrderedTestsMenuItemId && activeMenuItemId !== allPanelsMenuItemId,
    [activeMenuItemId],
  )

  const panelList = React.useMemo(() => {
    //handle loading list from params
    if (activeMenuItemId === undefined) {
      //return undefined to trigger loading and wait for the update
      return undefined
    }
    //handle Frequently Ordered Tests
    if (activeMenuItemId === frequentlyOrderedTestsMenuItemId) {
      return frequentlyOrderedList
    }
    //handle All tests selected
    if (activeMenuItemId === allPanelsMenuItemId) {
      if (!filteredSubCategories?.length) {
        // if no subcategory is provided -> show all panel list filtered by frequently ordered list
        if (isFrequentlyOrderedListProvided) {
          return prependFrequentlyOrderedList(panels ?? [])
        }
        return panels
      }
      const allPanels: CategoryV2PanelDto[] = []
      filteredSubCategories.forEach(item => {
        const categoryPanelsList = panels?.filter(el => item?.panelIds?.includes(el.code!)) ?? []
        allPanels.push(...sortFullPanelListByListFromContentful(categoryPanelsList, item?.panelIds ?? []))
      })
      if (isFrequentlyOrderedListProvided) {
        return prependFrequentlyOrderedList(uniqBy(allPanels, 'code'))
      }
      return uniqBy(allPanels, 'code')
    }
    //Handle sub-category selected
    const activeItemIdForSubcategory = activeMenuItemId - 1 // -1 and 0 are reserved for all items list and frequently ordered list.
    //so active item from subcategory menu will be 1, but in filteredSubCategories list it should be 0
    if (isSubcategoryActiveItemId && filteredSubCategories && filteredSubCategories[activeItemIdForSubcategory]) {
      const panelIds = filteredSubCategories[activeItemIdForSubcategory]?.panelIds
      const categoryPanelsList = panels?.filter(item => panelIds?.includes(item.code!)) ?? []
      return sortFullPanelListByListFromContentful(categoryPanelsList, panelIds ?? [])
    }
  }, [activeMenuItemId, isSubcategoryActiveItemId, filteredSubCategories, isFrequentlyOrderedListProvided])

  return (
    <CategorySideMenuContext.Provider
      value={{
        setActiveMenuItemId: activeItemId => setActiveMenuItemId(activeItemId),
      }}
    >
      <CategorySideMenuWrapper menuList={panelMenuList}>
        <PanelCardsItemSelectedContext.Provider
          value={{
            addonOpenedId,
            sectionHeading: 'mainCategoryPanelTab',
            setSelectedAddon,
            setSelectedTooltip,
            tooltipOpenedId,
          }}
        >
          {panelList ? (
            panelList.map((panel, index) => (
              <PanelItem
                index={index}
                isLastPanel={index === panelList.length - 1}
                key={panel.code}
                panel={panel}
                showGeneCount={true}
              />
            ))
          ) : (
            <NvSpinner isLoading />
          )}
        </PanelCardsItemSelectedContext.Provider>
      </CategorySideMenuWrapper>
    </CategorySideMenuContext.Provider>
  )
}

export default PanelTab
