import TestCatalogAPI from 'api/testCatalogApi'
import {IGenesTabItem} from 'components/CategoryTabSection/GenesTab/GenesTabItem'

const serializeFrequentlyOrderedGene = ({code, name, alias}: IGenesTabItem & Record<string, any>) => ({
  alias,
  code,
  name,
})

export const fetchMainCategoryFrequentlyOrderedGenes = async (geneIds: string[]): Promise<IGenesTabItem[]> => {
  const frequentlyOrderedGenes = await Promise.all(
    geneIds.map(async id => {
      const testCatalogApi = new TestCatalogAPI()
      const gene = (await testCatalogApi.getGeneByPRCode(id)).gene
      return serializeFrequentlyOrderedGene(gene)
    }),
  )

  return frequentlyOrderedGenes
}
