import * as React from 'react'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {AddToCart} from '@invitae/add-to-cart'
import {NvButton} from '@invitae/ids-react'
import {useAnalyticsQueue, useCountryLocationPrefix} from '@invitae/nucleobase'

import {CONFIRM_ADD_TO_CART_ANALYTICS_EVENT} from 'constants/analytics'
import {Authenticating} from 'constants/authState'
import {GENE, useGetLinkDetailPage} from 'hooks/useGetLinkToDetailPage'
import useHandleGeneSelection from 'hooks/useHandleGeneSelection.hooks'

import styles from './GenesTabItem.module.scss'

export interface IGenesTabItem {
  name?: string
  code?: string
  alias?: string
  keyword?: string
  mainCategoryTitle?: string
}

const GenesTabItem = ({name, code, alias, keyword, mainCategoryTitle}: IGenesTabItem) => {
  const {geneDetailLink} = useGetLinkDetailPage(GENE, code!)
  const {logEvent, linkClickLogEvent} = useAnalyticsQueue()
  const {authState} = React.useContext(UserAuthStatusContext)
  const section = React.useMemo(() => (keyword ? 'Search for genes' : 'Frequently ordered genes'), [keyword])
  const {removeUrlPrefix} = useCountryLocationPrefix()
  const logGeneEvent = ({isNameClicked}: {isNameClicked: boolean}) => {
    const linkName = isNameClicked ? 'gene name' : 'see gene details'
    linkClickLogEvent({
      currentPage: 'test catalog main category page',
      eventProperties: {
        'Link Destination': removeUrlPrefix(geneDetailLink),
        section,
      },
      linkName,
    })
    return true
  }

  const logAddToCartEvent = React.useCallback(() => {
    logEvent({
      eventName: CONFIRM_ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'Gene Name': name,
        section,
      },
    })
  }, [name, section])

  const {showAdded, handleAddToCart} = useHandleGeneSelection({geneCode: code!, logAddToCartEvent})

  const handleRedirectToGene = React.useCallback(() => {
    logGeneEvent({isNameClicked: false})
    window.location.assign(geneDetailLink)
  }, [geneDetailLink, logGeneEvent])
  const clinicalAreaProperties: {[key: string]: string} = {[name || '']: mainCategoryTitle || ''}
  return (
    <div className={styles.geneRoot} data-testid="genes-tab-item">
      <a
        className={styles.name}
        data-testid="gene-name-link"
        href={geneDetailLink}
        onClick={() => {
          logGeneEvent({isNameClicked: true})
        }}
      >
        {name}
      </a>
      {alias && <p className={styles.alias}>Alias: {alias}</p>}
      <div className={styles.rightContainer}>
        <NvButton
          className={styles.seeMoreButton}
          dataTestId="gene-see-detail"
          onClick={handleRedirectToGene}
          size="medium"
          variant="secondary"
        >
          See gene details
        </NvButton>
        <AddToCart
          className={styles.addButton}
          clinicalAreaProperties={clinicalAreaProperties}
          dataTestId="gene-add-btn"
          disabled={showAdded || authState === Authenticating}
          onClick={() => !showAdded && handleAddToCart()}
          productId={`${code}`}
          size="medium"
          variant="primary"
        >
          {showAdded ? 'Added' : 'Add to order'}
        </AddToCart>
      </div>
    </div>
  )
}

export default GenesTabItem
