import * as React from 'react'

function IconCheckboxActive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height={24} viewBox="0 0 24 24" width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.047 5.735a1.333 1.333 0 012.016 1.737l-.11.127-10.43 10.667a1.334 1.334 0 01-1.757.132l-.127-.11-5.569-5.425a1.333 1.333 0 011.734-2.02l.127.11 4.615 4.497 9.501-9.715z"
        fill="#fff"
      />
    </svg>
  )
}

export default IconCheckboxActive
