import * as React from 'react'

const GeneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill="none" height={18} width={14} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      // eslint-disable-next-line spellcheck/spell-checker
      d="M.001 17.406c-.017.278.18.594.536.594h.535a.536.536 0 00.532-.506c.01-.159.035-.37.077-.619h9.981c.042.25.073.471.087.622a.54.54 0 00.532.503h.54c.4 0 .549-.376.531-.59-.073-1.038-.566-4.458-3.773-7.341-.438.327-.911.64-1.422.938.316.264.59.535.855.809H4.3a11.766 11.766 0 012.758-2.085c5.456-3.034 6.2-7.762 6.298-9.137C13.373.316 13.175 0 12.82 0h-.535a.536.536 0 00-.532.506c-.01.158-.035.37-.077.619H1.691a6.874 6.874 0 01-.083-.622A.54.54 0 001.076 0h-.54C.183 0-.015.32.002.594c.09 1.255.737 5.38 5.144 8.399C.742 12.003.09 16.133 0 17.406zM11.214 2.813a9.413 9.413 0 01-.842 1.687h-7.37a9.69 9.69 0 01-.845-1.688h9.056zm-4.536 5.23a11.445 11.445 0 01-2.347-1.855H9.04a11.703 11.703 0 01-2.362 1.856zm-4.542 7.145c.199-.524.47-1.097.839-1.688h7.362c.369.59.647 1.16.849 1.688h-9.05z"
      fill="#128279"
    />
  </svg>
)

export default GeneIcon
