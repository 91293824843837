import * as React from 'react'
import classnames from 'classnames'

import GeneCoverageSearchTool from '@invitae/gene-coverage-search-tool'

import styles from './GCSTComponentBlock.module.scss'

const GCSTComponentBlock = () => {
  return <GeneCoverageSearchTool className={classnames('nv-container', styles.GCSTComponentBlockRoot)} />
}

export default GCSTComponentBlock
