import * as React from 'react'

function IconClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height={12} viewBox="0 0 16 16" width={16} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M1.864.256a1.143 1.143 0 00-1.53 1.695L6.385 8l-6.05 6.049-.078.087a1.143 1.143 0 001.695 1.53L8 9.615l6.049 6.05.087.078a1.143 1.143 0 001.53-1.695L9.615 8l6.05-6.049.078-.087a1.143 1.143 0 00-1.695-1.53L8 6.385 1.951.334 1.864.257z"
        fill="#A8AAB3"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default IconClose
