import * as React from 'react'
import classnames from 'classnames'

import {Grid} from '@invitae/nucleobase'

import IconCardSectionItem, {IconCardItemProps} from './IconCardItem'

import styles from './IconCards.module.scss'

interface IIconCardsProps {
  items: IconCardItemProps[]
  sectionName?: string
}

const IconCards = ({items, sectionName}: IIconCardsProps) => {
  const iconCardSectionGridClassName = classnames(styles.cardGrid, {
    [styles.cardNarrowGrid]: items?.length > 3,
    [styles.cardWideGrid]: items?.length === 3,
    [styles.cardTwoItemsGrid]: items?.length === 2,
    [styles.cardOneItemsGrid]: items?.length === 1,
  })
  return (
    <Grid className={iconCardSectionGridClassName} data-testid="icon-cards-section" withoutContainer={true}>
      {items.map(item => (
        <IconCardSectionItem key={item.heading?.toString()} {...item} sectionName={sectionName} />
      ))}
    </Grid>
  )
}
export default IconCards
