import * as React from 'react'

function IconArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height={10} width={7} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M6.16238 4.82225L1.08895 0.0691244C0.972129 -0.0230415 0.771863 -0.0230415 0.638351 0.0691244L0.0876168 0.503621C-0.0292056 0.595787 -0.0292056 0.753785 0.0876168 0.859118L4.37667 4.99342L0.104306 9.14088C-0.0125167 9.23305 -0.0125167 9.39105 0.104306 9.49638L0.65504 9.93088C0.771863 10.023 0.972129 10.023 1.10564 9.93088L6.16238 5.17775C6.27921 5.07242 6.27921 4.91442 6.16238 4.82225Z"
        fill="#161616"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default IconArrow
