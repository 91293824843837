import * as React from 'react'
import classNames from 'classnames'
import kebabCase from 'lodash'
import {PanelCardsItemSelectedContext} from 'wrapper/PanelCardsContext'

import {IPanelCardsSectionFields} from '@invitae/contentful-types'
import {NvHeading} from '@invitae/ids-react'
import {BaseHeading, BaseSection, Grid} from '@invitae/nucleobase'

import Breadcrumbs from 'components/Breadcrumbs'
import {parseHtml} from 'utils/utils'

import styles from './PanelCardsSection.module.scss'

interface IPanelCardsFields extends Omit<IPanelCardsSectionFields, 'items'> {
  id: string
  subHeading?: string
  subtext?: string
  items: React.ReactNode[]
  customRootClassname?: string
}

const PanelCardsSection: React.FC<IPanelCardsFields> = ({
  internalName,
  items,
  heading,
  analyticEvents,
  theme,
  showBreadcrumbs,
  subHeading,
  subtext,
  id,
  customRootClassname,
}) => {
  const className = classNames(styles[theme], {
    [styles.panelCardsBreadcrumbs]: showBreadcrumbs,
  })

  const [tooltipOpenedId, setTooltipOpenedId] = React.useState('')
  const [addonOpenedId, setAddonOpenedId] = React.useState('')
  const setSelectedTooltip = React.useCallback((tooltipId: string) => {
    setTooltipOpenedId(tooltipId)
  }, [])

  const setSelectedAddon = React.useCallback((cardId: string) => {
    setAddonOpenedId(cardId)
  }, [])

  return (
    <div>
      {showBreadcrumbs && <Breadcrumbs isGray />}
      <>
        {/** show nv spinner when the panel cards info is loaded */}

        {/** show main component when all panel cards are loaded */}
        <BaseSection analyticsEvents={analyticEvents} id={id}>
          <div
            className={classNames(className)}
            data-cy={`PanelCardsSection_${kebabCase(internalName)}`}
            data-testid="panel-cards-section"
          >
            <div className={classNames(styles.root, customRootClassname)}>
              {heading && <BaseHeading heading={heading} headingAlignment="left" />}
              {subHeading && (
                <NvHeading className={classNames('nv-container', styles.subHeading)} element="h4" level={3}>
                  {subHeading}
                </NvHeading>
              )}
              {subtext && (
                <p className={classNames('nv-container', styles.subtext)} data-testid="subtext">
                  {parseHtml(subtext)}
                </p>
              )}
              <Grid component="ul" data-cy={`PanelCardsSection_${kebabCase(internalName)}_grid`}>
                <PanelCardsItemSelectedContext.Provider
                  value={{
                    addonOpenedId,
                    sectionHeading: heading || 'panelCardsSection',
                    setSelectedAddon,
                    setSelectedTooltip,
                    tooltipOpenedId,
                  }}
                >
                  {items}
                </PanelCardsItemSelectedContext.Provider>
              </Grid>
            </div>
          </div>
        </BaseSection>
      </>
    </div>
  )
}

export default PanelCardsSection
