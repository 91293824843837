import React, {ReactNode} from 'react'
import {useInView} from 'react-intersection-observer'
import classNames from 'classnames'
import {ReproductiveTestCodes as testCodes} from 'common/testCodes'
import deepcopy from 'ts-deepcopy'
import {UserAuthStatusContext} from 'wrapper/UserAuthStatusContext'

import {AddToCart} from '@invitae/add-to-cart'
import {useAnalyticsQueue} from '@invitae/nucleobase'
import {CartContext} from '@invitae/physician-cart'
import {OptTestDto} from '@invitae/stargate'

import Breadcrumbs from 'components/Breadcrumbs'
import IconCards from 'components/IconCards/IconCards'
import {ADD_TO_CART_ANALYTICS_EVENT, CONFIRM_ADD_TO_CART_ANALYTICS_EVENT} from 'constants/analytics'
import {Authenticated, Authenticating, Unauthenticated} from 'constants/authState'
import {getCleanCode} from 'utils/utils'

import ReproductiveBasePanelItem from './ReproductiveBasePanelItem'

import styles from './ReproductiveBaseComponentBlock.module.scss'

export interface ReproductiveBasePanel {
  fields: {
    code: string
    optTests: OptTestDto[]
    name: string
    totalGenesCount?: number
    geneTests: {
      fields: {[x: string]: any}
    }
  }
}

export interface ReproductiveBaseComponentBlockProps {
  requiredPanels?: string[]
  exclusiveTests?: RegExp[]
  defaultCheckedTests?: RegExp[]
  title: string
  imgUrl: string
  packageName: string
  packageType: 'early pregnancy' | 'pre-pregnancy'
  imageClassName: string
  description: ReactNode
  isLoading: boolean
  panels: ReproductiveBasePanel[]
  iconCards: {
    heading: ReactNode
    icon: ReactNode
    url: string
  }[]
}

export enum ComponentBlockStatus {
  INITIAL = 'initial',
  SELECTING_PANELS = 'selecting',
  DONE = 'done',
}

export interface SelectedTest {
  [x: string]: boolean
}

const PRIMARY_GENES = ['CFTR', 'SMN1']
const PRIMARY_ADDON_CODE = 'addon-code'

const prepareComprehensiveCarrierPanel = (comprehensiveCarrierPanel: ReproductiveBasePanel) => {
  // avoid mutating useQuery hook's cache
  const panel = deepcopy(comprehensiveCarrierPanel)

  let totalGenesCount = panel.fields.totalGenesCount || 0
  panel.fields.optTests?.forEach(test => {
    totalGenesCount += test.totalGenesCount || 0
  })
  if (totalGenesCount > 0) panel.fields.totalGenesCount = totalGenesCount - 1

  const addOnGenes = panel.fields.geneTests?.fields.geneTests.filter(
    (test: {[x: string]: any}) => !PRIMARY_GENES.includes(test.fields.geneTest.name),
  )
  const primaryAddOnTests: OptTestDto = {
    code: PRIMARY_ADDON_CODE,
    geneTests: addOnGenes.map((gene: {[x: string]: any}) => gene.fields.geneTest),
    name: `Add-on ${panel.fields.name} genes`,
    totalGenesCount: addOnGenes.length,
  }

  panel.fields.optTests = [primaryAddOnTests, ...(panel.fields.optTests || [])]

  panel.fields.geneTests!.fields.geneTests = panel.fields.geneTests?.fields.geneTests.filter(
    (test: {[x: string]: any}) => PRIMARY_GENES.includes(test.fields.geneTest.name),
  )

  return panel
}

const ReproductiveBaseComponentBlock = ({
  requiredPanels = [PRIMARY_ADDON_CODE],
  exclusiveTests = [],
  defaultCheckedTests = [],
  isLoading,
  panels,
  iconCards,
  title,
  imgUrl,
  description,
  packageName,
  imageClassName,
  packageType,
}: ReproductiveBaseComponentBlockProps) => {
  panels = panels.map(panel => {
    if (getCleanCode(panel.fields.code || '') === getCleanCode(testCodes.INVITAE_COMPREHENSIVE_CARRIER_SCREEN))
      return prepareComprehensiveCarrierPanel(panel)
    return panel
  })

  const fullAddonList = React.useMemo(() => panels.flatMap(panel => panel.fields.optTests), [panels])

  const initialSelectedTests: SelectedTest = React.useMemo(() => {
    const selected: SelectedTest = {}
    defaultCheckedTests.forEach(regex => {
      const matchedItem = fullAddonList.find(addon => regex.test(addon?.code || ''))
      if (matchedItem) {
        selected[matchedItem.code] = true
      }
    })
    return selected
  }, [defaultCheckedTests, fullAddonList])

  const {addToCart} = React.useContext(CartContext)
  const [isAddingToCart, setAddingToCart] = React.useState(false)
  const [selectedTests, setSelectedTests] = React.useState<SelectedTest>(initialSelectedTests)
  const [status, setStatus] = React.useState<ComponentBlockStatus>(ComponentBlockStatus.INITIAL)
  const {logEvent} = useAnalyticsQueue()
  const {ref, inView} = useInView({threshold: 0.6})
  const isPrePregnancy = React.useMemo(() => packageType === 'pre-pregnancy', [packageType])
  const sectionName = React.useMemo(
    () => (isPrePregnancy ? 'trying to conceive' : 'if your patient is pregnant'),
    [isPrePregnancy],
  )

  const viewSectionAnalytics = `view '${sectionName}' section on reproductive clinical center page`

  const {authState} = React.useContext(UserAuthStatusContext)

  const redirectToSignIn = React.useCallback(() => {
    window.location.assign(`${process.env.SIGN_IN_PAGE}?next=/providers/test-catalog/reproductive`)
  }, [])

  const selectedCodes = Object.entries(selectedTests)
    .filter(entry => !!entry[1]) // we filter selected tests, unselected test are { [code]: false }
    .map(([key]) => key)

  const isPanelCustomized = React.useCallback(() => {
    if (!panels.some(item => item.fields.optTests)) return 'N/A'
    if (
      selectedCodes.length === 2 &&
      selectedCodes.includes(panels[0].fields.code) &&
      selectedCodes.includes(panels[1].fields.code)
    )
      return 'No'
    return 'Yes'
  }, [selectedCodes])

  const logConfirmAddToCartEvent = React.useCallback(() => {
    logEvent({
      eventName: CONFIRM_ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'current page': window.location.href,
        customized: isPanelCustomized(),
        'Panel Name': panels.map(item => item.fields.name).join(' '),
        section: title,
      },
    })
  }, [panels, title])

  const logAddToCartEvent = React.useCallback(() => {
    logEvent({
      eventName: ADD_TO_CART_ANALYTICS_EVENT,
      eventProperties: {
        'current page': window.location.href,
        'Panel Name': packageName,
        section: title,
      },
    })
  }, [packageName, title])

  const handleClick = React.useCallback(async () => {
    if (isLoading || isAddingToCart || status === ComponentBlockStatus.DONE) {
      return
    }
    if (status === ComponentBlockStatus.SELECTING_PANELS) {
      if (authState === Authenticated) {
        setAddingToCart(true)
        const panelCodes = [panels[0].fields.code, panels[1].fields.code, ...selectedCodes]
        await addToCart(panelCodes, undefined, [{codes: panelCodes, parentId: '', parentType: ''}])
        logConfirmAddToCartEvent()
        setAddingToCart(false)
        setStatus(ComponentBlockStatus.DONE)
      } else if (authState === Unauthenticated) {
        redirectToSignIn()
      }
    } else {
      logAddToCartEvent()
      setStatus(ComponentBlockStatus.SELECTING_PANELS)
    }
  }, [status, setStatus, isLoading, addToCart, isAddingToCart, selectedCodes, panels, authState, redirectToSignIn])

  React.useEffect(() => {
    //log view analytics even when the section becomes visible to the user
    if (inView) {
      logEvent({
        eventName: viewSectionAnalytics,
        eventProperties: {},
      })
    }
  }, [inView, logEvent, ref])

  return (
    <>
      {!isPrePregnancy && <Breadcrumbs isGray />}
      <section className={classNames(styles.root, 'nv-container')} id="pregnancy" ref={ref}>
        <div className={styles.content}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.packageCard}>
            <div className={styles.imageContainerMobile}>
              <div className={styles.imageWrapper}>
                <img alt="" className={imageClassName} src={imgUrl} />
              </div>
            </div>
            <div className={styles.imageRow}>
              <div>
                <div className={styles.titleRowContainer}>
                  <h3 className={styles.packageCardTitle}>{packageName}</h3>
                </div>
                <p>{description}</p>
              </div>
              <div className={styles.imageContainerDesktop}>
                <div className={styles.imageWrapper}>
                  <img alt="" className={imageClassName} src={imgUrl} />
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.packageCardTests, {
                [styles.addingPackages]: status === ComponentBlockStatus.SELECTING_PANELS,
              })}
            >
              {panels.map((panel, index) => (
                <ReproductiveBasePanelItem
                  exclusiveTests={exclusiveTests}
                  isLoading={isLoading}
                  key={index}
                  packageType={packageType}
                  panel={panel}
                  panels={panels}
                  requiredPanels={requiredPanels}
                  selectedCodes={selectedCodes}
                  selectedTests={selectedTests}
                  setSelectedTests={setSelectedTests}
                  status={status}
                />
              ))}
            </div>
            <div className={styles.buttonContainer}>
              <AddToCart
                className={classNames(styles.addPackageButton, {
                  [styles.disabled]:
                    isLoading || isAddingToCart || status === ComponentBlockStatus.DONE || authState === Authenticating,
                })}
                disabled={
                  isLoading || isAddingToCart || status === ComponentBlockStatus.DONE || authState === Authenticating
                }
                onClick={handleClick}
                productId={[panels[0]?.fields.code, panels[1]?.fields.code, ...selectedCodes]}
                size="medium"
              >
                {status === ComponentBlockStatus.DONE && 'Added'}
                {status === ComponentBlockStatus.INITIAL && 'Add package'}
                {status === ComponentBlockStatus.SELECTING_PANELS && 'Confirm selection'}
              </AddToCart>
            </div>
          </div>
          <IconCards items={iconCards} sectionName={sectionName} />
        </div>
      </section>
    </>
  )
}

export default ReproductiveBaseComponentBlock
