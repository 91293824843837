import React from 'react'

import {useCountryLocationPrefix} from '@invitae/nucleobase'

import MainCategoryContext from 'components/MainCategory/MainCategoryContext'
import {getCleanCode} from 'utils/utils'

export const PANEL = 'panel'
export const GENE = 'gene'

export const useGetLinkDetailPage = (categoryType: 'panel' | 'gene', code: string) => {
  const {code: catCode} = React.useContext(MainCategoryContext).mainCategory
  const [geneDetailLink, setGeneDetailLink] = React.useState('')
  const [panelDetailLink, setPanelDetailLink] = React.useState('')
  const {pathCountryCode} = useCountryLocationPrefix()

  const getPanelDetailLink = React.useCallback(() => {
    const panelCode = getCleanCode(code)
    setPanelDetailLink(`/${pathCountryCode.toLowerCase()}/providers/test-catalog/test-${panelCode}`)
  }, [code, catCode, pathCountryCode])

  const getGeneDetailLink = React.useCallback(() => {
    const prCode = getCleanCode(code)
    setGeneDetailLink(`/${pathCountryCode.toLowerCase()}/providers/test-catalog/gene-${prCode}`)
  }, [code, pathCountryCode])

  React.useEffect(() => {
    if (categoryType === PANEL) getPanelDetailLink()
    else if (categoryType === GENE) getGeneDetailLink()
  }, [categoryType, code, getGeneDetailLink, getPanelDetailLink])

  return {geneDetailLink, panelDetailLink}
}
