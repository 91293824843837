import deepcopy from 'ts-deepcopy'

import {
  CategoryV2PanelDto,
  GeneTestDto,
  OptionalTestDto,
  OptTestForPanelDetailedPageDto,
  PanelCardDto,
  PanelDetailedPageDto,
} from '@invitae/stargate'

import {
  ADD_ON_SEX_CHR_ANEUPLOIDIES_WILL_PREDICT_FETAL_SEX_REGEX,
  CARRIER_PANEL_CODE_PRIMARY_GENE_1,
  CARRIER_PANEL_CODE_PRIMARY_GENE_2,
  CARRIER_PANEL_CODE_PRIMARY_NAME_DEFAULT_GENE_1,
  CARRIER_PANEL_CODE_PRIMARY_NAME_DEFAULT_GENE_2,
  carrierCategoryPanelCodes,
  NIPS_CORE_EXPANDED_SCA_SEX_ADDONS_REGEX,
  NIPS_CORE_SCA_SEX_ADDONS_REGEX,
  requiredPanelAddonsCodes,
} from 'constants/specialCases'
import {IOptionalTestData, IPanelData, IPanelGene} from 'hooks/types'
import {getCleanCode} from 'utils/utils'

export interface IGetComponentFriendlyDataFromAPIData {
  primaryPanelData: IPanelData
  optionalTestsData?: IOptionalTestData[]
}

/**
 * Both types to process both panel data from panel API and main category API
 */
type TPrimaryPanelAPIData = CategoryV2PanelDto & (PanelDetailedPageDto | PanelCardDto)

type TOptionalTestItemAPIData = OptionalTestDto & OptTestForPanelDetailedPageDto
type TOptionalTestsAPIData = TOptionalTestItemAPIData[]

/**
 * "Invitae Common Hereditary Cancers + RNA Panel" panel has required "Common Hereditary Cancers Genes Eligible for RNA Analysis" addon
 * "Invitae Multi-Cancer + RNA Panel" has required "Multi-Cancer Genes Eligible for RNA Analysis" addon
 */

const isPanelAddonDefaultChecked = (addonCode: string): boolean =>
  !!addonCode.match(ADD_ON_SEX_CHR_ANEUPLOIDIES_WILL_PREDICT_FETAL_SEX_REGEX)

const isPanelAddonRequired = (addonCode: string) => requiredPanelAddonsCodes.some(item => addonCode.includes(item))

const isPanelAddonUnique = (addonCode: string): boolean =>
  !!addonCode.match(NIPS_CORE_SCA_SEX_ADDONS_REGEX) || !!addonCode.match(NIPS_CORE_EXPANDED_SCA_SEX_ADDONS_REGEX)

const handleCarrierPanelSplitPrimaryGenes = (
  primaryPanelGeneList: IPanelGene[],
  primaryData: TPrimaryPanelAPIData,
  newAddonItemTitle: string,
) => {
  /**
   * separate genes 'CFTR' and 'SMN1' to primary panel as one item, change gene count of primary panel to 2
   * create new optional test item "Add-on ... Carrier Screen genes" for every other gene left from primary panel and update the gene count
   * also make "Add-on ... Carrier Screen genes" required so user cannot remove it
   * additionally based on the panel from Carrier screen we provide custom title for new optional test item
   */
  const copyList = deepcopy(primaryPanelGeneList)
  const getFirstItem = primaryPanelGeneList.find(item => item.name === CARRIER_PANEL_CODE_PRIMARY_GENE_1) || {
    code: CARRIER_PANEL_CODE_PRIMARY_NAME_DEFAULT_GENE_1,
    name: CARRIER_PANEL_CODE_PRIMARY_GENE_1,
  }
  const getSecondItem = primaryPanelGeneList.find(item => item.name === CARRIER_PANEL_CODE_PRIMARY_GENE_2) || {
    code: CARRIER_PANEL_CODE_PRIMARY_NAME_DEFAULT_GENE_2,
    name: CARRIER_PANEL_CODE_PRIMARY_GENE_2,
  }
  const updatedMainGeneList = [getFirstItem, getSecondItem]
  const updatedPrimaryPanelTotalGeneCount = 2

  const geneListWithoutPrimaryItemsGenes = copyList.filter(
    gene => gene.name !== CARRIER_PANEL_CODE_PRIMARY_GENE_1 && gene.name !== CARRIER_PANEL_CODE_PRIMARY_GENE_2,
  )
  const newOptionalTestsItemWithoutPrimaryItemData = {
    geneList: geneListWithoutPrimaryItemsGenes,
    isDefaultChecked: false,
    isRequired: true,
    isUnique: false,
    panelCode: primaryData.code,
    title: newAddonItemTitle,
    totalGenesCount: (primaryData.totalGenesCount ?? 0) - 2 /** 2 - length of genes in primary test */,
  }
  const updatedOptTestsData = [newOptionalTestsItemWithoutPrimaryItemData]

  return {
    updatedMainGeneList,
    updatedOptTestsData,
    updatedPrimaryPanelTotalGeneCount,
  }
}

const getOptionalTestsDataFromAPIItem = (optionalTestFromAPI: TOptionalTestItemAPIData) => {
  const geneList = optionalTestFromAPI.geneTests?.map((test: GeneTestDto) => ({code: test.code, name: test.name}))
  const itemTotalGenesCount = optionalTestFromAPI.totalGenesCount

  return {
    description: optionalTestFromAPI.description,
    geneList: geneList,
    isDefaultChecked: isPanelAddonDefaultChecked(optionalTestFromAPI?.code ?? ''),
    isRequired: isPanelAddonRequired(optionalTestFromAPI?.code ?? ''),
    isUnique: isPanelAddonUnique(optionalTestFromAPI?.code ?? ''),
    panelCode: optionalTestFromAPI.code,
    productType: optionalTestFromAPI.productType,
    title: optionalTestFromAPI.name,
    totalGenesCount: itemTotalGenesCount,
  }
}

const getComponentFriendlyPanelDataFromAPIData = (
  primaryPanelAPIData: TPrimaryPanelAPIData,
  optionalTestsAPIData?: TOptionalTestsAPIData,
): IGetComponentFriendlyDataFromAPIData => {
  let primaryPanelData: IPanelData | undefined = undefined
  let optionalTestsData: IOptionalTestData[] | undefined = undefined
  const cleanPanelCode = getCleanCode(primaryPanelAPIData?.code ?? '')

  let primaryPanelGeneList: IPanelGene[] =
    primaryPanelAPIData.geneTests?.map((test: GeneTestDto) => ({
      code: test.code!,
      name: test.name!,
    })) ?? []
  let primaryPanelTotalGeneCount = primaryPanelAPIData.totalGenesCount

  /**
   * Handle Spcial cases for Carrier screen
   *
   * separate genes 'CFTR' and 'SMN1' to primary panel as one item, change gene count of primary panel to 2
   * create new optional test item "Add-on ... Carrier Screen genes" for every other gene left from primary panel and update the gene count
   * also make "Add-on ... Carrier Screen genes" required so user cannot remove it
   * additionally based on the panel from Carrier screen we provide custom title for new optional test item
   */

  if (carrierCategoryPanelCodes[cleanPanelCode]) {
    const newAddonItemTitle = carrierCategoryPanelCodes[cleanPanelCode]
    const {updatedMainGeneList, updatedPrimaryPanelTotalGeneCount, updatedOptTestsData} =
      handleCarrierPanelSplitPrimaryGenes(primaryPanelGeneList, primaryPanelAPIData, newAddonItemTitle)
    primaryPanelGeneList = updatedMainGeneList
    primaryPanelTotalGeneCount = updatedPrimaryPanelTotalGeneCount
    optionalTestsData = updatedOptTestsData
  }
  primaryPanelData = {
    categories: primaryPanelAPIData.categories,
    geneList: primaryPanelGeneList,
    panelCode: primaryPanelAPIData.code,
    productType: primaryPanelAPIData.productType,
    title: primaryPanelAPIData.name,
    totalGenesCount: primaryPanelTotalGeneCount,
  }

  if (optionalTestsAPIData) {
    const optionalTestList = optionalTestsAPIData.map((optionalTestFromAPI: TOptionalTestItemAPIData) =>
      getOptionalTestsDataFromAPIItem(optionalTestFromAPI),
    )
    //if optionalTestsData already exists it means we need to extend the list, otherwise update the undefined value

    const optionalTestListWithProvidedData = optionalTestList.map(item => ({
      ...item,
      geneList: item.geneList ? item.geneList.map(gene => ({code: gene.code!, name: gene.name!})) : [],
    }))

    if (optionalTestsData) {
      optionalTestsData = [...optionalTestsData, ...optionalTestListWithProvidedData]
    } else {
      optionalTestsData = optionalTestListWithProvidedData
    }
  }
  return {
    optionalTestsData,
    primaryPanelData,
  }
}

export default getComponentFriendlyPanelDataFromAPIData
