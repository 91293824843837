import * as React from 'react'
import classNames from 'classnames'

import {NvTooltip} from '@invitae/ids-react'

import styles from './PanelTagSponsored.module.scss'

const tooltipText =
  'In addition to insurance and patient-pay billing options, this test is also available through a sponsored, no-charge testing program. See test details for more information.'
const sponsoredTesting = 'SPONSORED'

const PanelTagSponsored = ({
  onChangeVisibleTooltip,
  className,
}: {
  onChangeVisibleTooltip: ({duration, tagName}: {duration: number | null; tagName: string}) => void
  className?: string
}) => {
  return (
    <NvTooltip
      classes={{body: styles.tooltip}}
      onVisibilityChange={({duration}) => onChangeVisibleTooltip({duration, tagName: sponsoredTesting})}
      position="bottom"
      text={tooltipText}
    >
      <span className={classNames(styles.sponsoredTag, className)}>{sponsoredTesting}</span>
    </NvTooltip>
  )
}

export default PanelTagSponsored
