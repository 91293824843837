import * as React from 'react'
import {noop} from 'lodash'

import {Grid} from '@invitae/nucleobase'

import CategorySideMenu, {SideMenuItem} from './index'

import styles from './CategorySideMenu.module.scss'

interface ICategorySideMenuContextProps {
  setActiveMenuItemId: (itemId: number) => void
}

export const CategorySideMenuContext = React.createContext<ICategorySideMenuContextProps>({
  setActiveMenuItemId: () => noop,
})

interface ICategorySideMenuWrapperProps {
  children: React.ReactNode
  menuList: SideMenuItem[]
}

const CategorySideMenuWrapper = ({children, menuList}: ICategorySideMenuWrapperProps) => {
  return (
    <Grid className={styles.gridClass}>
      <div className={styles.categorySideMenuWrapper}>
        <div className={styles.sideMenu}>{!!menuList?.length && <CategorySideMenu menuList={menuList} />}</div>
        <div className={styles.innerChildWrapper}>{children}</div>
      </div>
    </Grid>
  )
}

export default CategorySideMenuWrapper
