import * as React from 'react'

function Blob(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="280" viewBox="0 0 614 280" width="614" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M385.332 83.0509C245.535 18.5589 127.129 0 0 0V280H614C614 280 539.641 154.237 385.332 83.0509Z"
        fill="#BFE8E4"
      />
    </svg>
  )
}

export default Blob
