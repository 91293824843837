import React from 'react'

import TestCatalogAPI from 'api/testCatalogApi'
import {IGenesTabItem} from 'components/CategoryTabSection/GenesTab/GenesTabItem'
import {sortGeneNamesByQuery, sortGenesAlphabetically} from 'utils/utils'

export const useGetMainCategoryGenes = (categoryCode: string, query?: string) => {
  const [loadingGenes, setLoadingGenes] = React.useState(false)
  const [genes, setGenes] = React.useState<IGenesTabItem[]>([])

  const getGenes = React.useCallback(async () => {
    setLoadingGenes(true)
    try {
      const testCatalogApi = new TestCatalogAPI()
      const genes = await testCatalogApi.getGenesByCategoryCode(categoryCode, query ?? '')
      const alphabeticSort = sortGenesAlphabetically(genes)
      const querySort = query ? sortGeneNamesByQuery(alphabeticSort, query) : alphabeticSort
      setGenes(querySort)
      setLoadingGenes(false)
    } catch {
      setLoadingGenes(false)
    }
  }, [categoryCode, query])

  React.useEffect(() => {
    if (query) {
      getGenes()
    }
  }, [categoryCode, query, getGenes])

  return {genes, loadingGenes, setLoadingGenes}
}
