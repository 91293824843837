import * as React from 'react'
import classNames from 'classnames'
import {useRouter} from 'next/router'

import {useAnalyticsQueue} from '@invitae/nucleobase'
import {useDetectMobileDevice} from '@invitae/react-hooks'

import {cleanUpUrlFromParams, formatSubcategoryTitle} from 'components/MainCategory/utils'
import {scrollTo} from 'utils/utils'

import {allPanelsMenuItemId, allPanelsMenuItemTitle} from '../PanelTab'
import {CategorySideMenuContext} from './CategorySideMenuWrapper'

import styles from './CategorySideMenu.module.scss'

export interface SideMenuItem {
  title: string
  id: number
}

interface ICategorySideMenuProps {
  menuList: SideMenuItem[]
}

export const QUERY_NAME = 'Category'

const CategorySideMenu = ({menuList}: ICategorySideMenuProps) => {
  const router = useRouter()
  const {linkClickLogEvent} = useAnalyticsQueue()
  const isMobile = useDetectMobileDevice()

  const {setActiveMenuItemId} = React.useContext(CategorySideMenuContext)
  const [activeItemId, setActiveItemId] = React.useState<number>()

  const getActiveItemId = React.useCallback(() => {
    const subCategoryNameFromUrl = router.query[QUERY_NAME]
    if (subCategoryNameFromUrl) {
      const activeItem = menuList.find(item => subCategoryNameFromUrl === item.title)
      //check if menu actually has the specified sub-category
      if (activeItem) {
        return activeItem.id
      } else {
        //return default if not
        return allPanelsMenuItemId
      }
      //return all tests by default
    } else return allPanelsMenuItemId
  }, [router, menuList])

  const routeToSelectedCategory = React.useCallback(
    (categoryName: string, id: number) => {
      !isMobile && scrollTo()

      linkClickLogEvent({
        currentPage: 'test catalog main category page',
        eventProperties: {
          'Current Category': router.query[QUERY_NAME] ?? allPanelsMenuItemTitle,
          'Destination Category': categoryName,
        },
        linkName: 'change category',
      })

      //skip routing if user clicks on main panel
      if (id === allPanelsMenuItemId) {
        router.replace({pathname: cleanUpUrlFromParams(router.asPath)}, undefined, {shallow: true})
        return
      }
      router.query[QUERY_NAME] = categoryName

      //shallow:true to disable the page from automatic reloading
      router.push(router, undefined, {shallow: true})
    },
    [router, setActiveMenuItemId, linkClickLogEvent, isMobile],
  )

  const setupRouter = React.useCallback(() => {
    const activeItemIdFromRouter = getActiveItemId()
    setActiveMenuItemId(activeItemIdFromRouter)
    setActiveItemId(activeItemIdFromRouter)
  }, [getActiveItemId, setActiveMenuItemId])

  React.useEffect(() => {
    //get active item from router
    setupRouter()
  }, [setupRouter])

  return (
    <div className={styles.categorySideMenu}>
      <p className={styles.categoryTitle}>Categories</p>
      {menuList.map(menuItem => (
        <div
          className={styles.menuItem}
          data-testid="category-side-menu-item"
          key={menuItem.id}
          onClick={() => routeToSelectedCategory(menuItem.title, menuItem.id)}
          onKeyDown={e => (e.key === 'Enter' ? routeToSelectedCategory(menuItem.title, menuItem.id) : null)}
          role="button"
          tabIndex={0}
        >
          <p
            className={classNames('nv-body', styles.menuTitle, {
              [styles.activeTitle]: activeItemId === menuItem.id,
            })}
          >
            {formatSubcategoryTitle(menuItem.title)}
          </p>
        </div>
      ))}
    </div>
  )
}

export default CategorySideMenu
